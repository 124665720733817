import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(events => events instanceof NavigationEnd),
      map(evt => this.activatedRoute),
      map(route => {
        while(route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
    ).pipe(
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data => {
      this.visibilityUpperNavLogged = data.upper_nav_logged;
      this.selectedUpperNavLoggedTab = data.selected_tab;
    })
  }

  title = 'E-gradinite';

  visibilityUpperNavLogged : boolean = false;
  selectedUpperNavLoggedTab: number = -1;
}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MainService} from '../services/main-service';
import {MainCommunication} from '../services/main-communication';
import {AuthorizationService} from '../services/authorization-service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  @ViewChild('despre_noi', {static: true}) despre_noi: ElementRef;
  @ViewChild('instructiune_de_aplicare', {static: true}) instructiune_de_aplicare: ElementRef;
  @ViewChild('inregistrare_view', {static: true}) inregistrare_view: ElementRef;

  constructor(private router: Router, private mainService: MainService, private mainCommunication: MainCommunication,
              private autorizareService: AuthorizationService) {

    this.mainCommunication.despreNoi$.subscribe((data) => {
      const despreNoiElement = this.despre_noi.nativeElement as HTMLElement;
      despreNoiElement.scrollIntoView();
    });

    this.mainCommunication.instructiuniDeAplicare$.subscribe((data) => {
      const instructiuniElement = this.instructiune_de_aplicare.nativeElement as HTMLElement;
      instructiuniElement.scrollIntoView();
    });

  }

  goToInregistrare() {
    const inregistrareElement = this.inregistrare_view.nativeElement as HTMLElement;
    inregistrareElement.scrollIntoView();
  }

  ngOnInit() {
    this.getLiveData();
  }

  codAutentificare = '';
  email = '';

  cereriDepuse = 0;
  cereriProcesate = 0;
  cereriAcceptate = 0;

  getLiveData() {
    this.mainService.getStatistica().subscribe((data) => {
      if(data.status) {
        this.cereriDepuse = data.cereri_depuse;
        this.cereriProcesate = data.cereri_procesate;
        this.cereriAcceptate = data.cereri_acceptate;
      }
    }, error => {
      console.log(error);
    })
  }

  autentificare() {
    if(this.codAutentificare.length == 0) {
      alert('Cimpul cu Codul de autentificare este obligatoriu');
    }
    else{
      this.autorizareService.logare_client(this.codAutentificare).subscribe((data) => {
        if(data.status) {
          localStorage.setItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza', data.token);
          localStorage.setItem('logged_in', 'customer');

          this.mainCommunication.loggedChanged();

          this.router.navigateByUrl('/parinte-main');
        }
        else {
          alert(data.message);
        }
      }, error => {
        alert('Codul de autentificare este gresit');
      })
    }
  }

  inregistrare() {
    if(this.email.length == 0) {
      alert('Cimpul de email este obligatoriu');
    }
    else if(!this.validateEmail(this.email)) {
      alert('Introduceti o adresa de email valida');
    }
    else {
      this.autorizareService.inregistrare_client(this.email).subscribe((data) => {
        if(data.status) {
          alert('Veti primi un cod de autentificare pe email-ul provizionat');
        }
        else{
          alert(data.message);
        }
      }, error => {
        alert('A intervenit o eroare la inregistrare');
      })
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}

<section class="customer-reg-container d-flex flex-column justify-content-center align-items-center w-100">
  <h1 class="position-relative width-fit header-default">Logare director <span class="position-relative">E-gradinita
      <span class="underline-header-container"><img src="../../assets/mainp/Group%206.png" class="underline-header"></span>
      </span></h1>
  <input [(ngModel)]="email" type="email" class="gen-input-white-bkg welcome-input w-100 mb-3 mt-5" placeholder="example@email.com">
  <input [(ngModel)]="password" type="password" class="gen-input-white-bkg welcome-input w-100 mb-3 mt-3" placeholder="Parola">
  <input [(ngModel)]="lastThree" type="text" class="gen-input-white-bkg welcome-input w-100 mb-4 mt-3" placeholder="Ultimele 3 cifre ale codului de activare">
  <span class="mt-3 mb-3 no-acc-text">* Este prima logare ? <span class="no-acc-link" (click)="goToActivare()">Activeaza contul acum !</span></span>
  <button (click)="autentificare()" class="button green main-log-button mt-3">Autentificare</button>
</section>

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {GradiniteService} from '../services/gradinite-service';
import {ParinteCommunication} from '../services/parinte-communication';
import {GradinitaFullModel} from '../models/gradinita/gradinita-full-model';

@Component({
  selector: 'app-customer-view-grad-details',
  templateUrl: './customer-view-grad-details.component.html',
  styleUrls: ['./customer-view-grad-details.component.css']
})
export class CustomerViewGradDetailsComponent implements OnInit {

  constructor(private router: Router, private gradiniteService: GradiniteService, private parinteCommunication: ParinteCommunication) {
    if(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza') == null) {
      this.router.navigateByUrl('/');
    }

    if(this.parinteCommunication.gradinita_id_selected == 0) {
      this.router.navigateByUrl('/parinte-main')
    }
  }

  ngOnInit() {
    this.currentTime = new Date().getTime();

    this.getGradinitaDetails();
  }

  currentTime: number = 0;

  gradinitaDetails: GradinitaFullModel = new GradinitaFullModel();

  limbeString : string = '';

  getGradinitaDetails() {
    this.gradiniteService.informatie_deplina(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'),
      String(this.parinteCommunication.gradinita_id_selected)).subscribe((data) => {
        if(data.status) {
          this.gradinitaDetails = data.gradinita;

          for(let i = 0; i < this.gradinitaDetails.limba_de_instruire.length; i++) {
            this.limbeString = this.limbeString + this.gradinitaDetails.limba_de_instruire[i];

            if(i != this.gradinitaDetails.limba_de_instruire.length - 1) {
              this.limbeString = this.limbeString + ' / ';
            }
          }
        }
    }, error => {
        console.log(error);
    })
  }

  deschideRegistrulPublic() {
    this.parinteCommunication.setGradinitaIdSelected(this.gradinitaDetails.id);
    this.parinteCommunication.setGradinitaNume(this.gradinitaDetails.nume);
    this.router.navigateByUrl('/registru-public');
  }

  inapoiLaLista() {
    this.parinteCommunication.gradinita_id_selected = 0;
    this.router.navigateByUrl('/parinte-main');
  }

  cerereNoua() {
    if(this.gradinitaDetails.locuri_disponibile > 0 && this.gradinitaDetails.termen_limita >= this.currentTime) {
      this.parinteCommunication.setGradinitaIdSelected(this.gradinitaDetails.id);
      this.router.navigateByUrl('/depune-cerere');
    }
  }

  openCerereDepusa() {
    this.parinteCommunication.setGradinitaIdSelected(this.gradinitaDetails.id);
    this.parinteCommunication.setCerereIdSelectd(this.gradinitaDetails.cerere_id);
    this.router.navigateByUrl('/modifica-cerere');
  }
}

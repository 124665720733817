import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MainCommunication} from '../services/main-communication';

@Component({
  selector: 'app-upper-tabs-logged',
  templateUrl: './upper-tabs-logged.component.html',
  styleUrls: ['./upper-tabs-logged.component.css']
})
export class UpperTabsLoggedComponent implements OnInit {

  @Input() selectedTab;

  constructor(private router: Router, private mainCommunication: MainCommunication) {
    this.mainCommunication.loggedChanged$.subscribe((data) => {
      if(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza') != null && localStorage.getItem('logged_in') == 'customer') {
        this.logged = true;
        this.loggedType = 0;
      }
      else if(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK') != null && localStorage.getItem('logged_in') == 'director') {
        this.logged = true;
        this.loggedType = 999;
      }
      else {
        this.logged = false;
        this.loggedType = -1;
      }
    })
  }

  ngOnInit() {
    if(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza') != null && localStorage.getItem('logged_in') == 'customer') {
      this.logged = true;
      this.loggedType = 0;
    }
    else if(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK') != null && localStorage.getItem('logged_in') == 'director') {
      this.logged = true;
      this.loggedType = 999;
    }
    else {
      this.logged = false;
      this.loggedType = -1;
    }

    if(localStorage.getItem('selected_year') == null) {
      this.selectedYear = 0;
    }
    else {
      this.selectedYear = 0;
    }

    this.firstYear = new Date().getFullYear();
    this.secondYear = this.firstYear - 1;
  }

  logged = false;
  loggedType = -1;

  selectedYear = 0;

  firstYear = 0;
  secondYear = 0;

  gradinitaSelected() {
    if(this.selectedTab != 0) {
      if(localStorage.getItem('logged_in') == 'customer') {
        this.router.navigateByUrl('/parinte-main');
      }
    }
  }

  cereriDepuseSelected() {
    if(this.selectedTab != 1) {
      if(localStorage.getItem('logged_in') == 'customer') {
        this.router.navigateByUrl('/cereri-depuse');
      }
    }
  }

  selectYear(position: number) {
    this.selectedYear = position;

    let year = this.firstYear;

    if(this.selectedYear == 1) {
      year = this.secondYear;
    }

    this.mainCommunication.changedYear(year);
  }

}

<section class="customer-reg-container d-flex flex-column justify-content-center align-items-center w-100">
  <h1 class="position-relative width-fit header-default mb-5">Completarea contului gradinitei <span class="position-relative">E-gradinita
      <span class="underline-header-container"><img src="../../assets/mainp/Group%206.png" class="underline-header"></span>
      </span></h1>


  <div class="inputs-label-container w-100 d-flex flex-column mb-3">
    <label class="inputs-label">Data limita pentru aplicare</label>
    <input [(ngModel)]="dataLimita" type="date" class="gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1">
  </div>
  <div class="inputs-label-container w-100 d-flex flex-column mb-3">
    <label class="inputs-label">Numar locuri disponibile</label>
    <input [(ngModel)]="numarLocuriDisponibile" type="number" class="gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1">
  </div>
  <div class="inputs-label-container w-100 d-flex flex-column mb-3">
    <label class="inputs-label">E-mail de contact</label>
    <input [(ngModel)]="emailDeContact" type="email" class="gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1">
  </div>
  <div class="inputs-label-container w-100 d-flex flex-column mb-3">
    <label class="inputs-label">Numar cadre didactice</label>
    <input [(ngModel)]="numarCadreDidactice" type="number" class="gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1">
  </div>
  <div class="inputs-label-container w-100 d-flex flex-column mb-3">
    <label class="inputs-label">Numar diplome</label>
    <input [(ngModel)]="numarDiplome" type="number" class="gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1">
  </div>
  <div class="inputs-label-container w-100 d-flex flex-column mb-3">
    <label class="inputs-label">Activitati (Indicati activitatile prin virgula)</label>
    <input [(ngModel)]="activitati" type="text" class="gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1">
  </div>
  <div class="inputs-label-container w-100 d-flex flex-column mb-3">
    <label class="inputs-label">Infrastructura (Indicati elementele de infrastructura prin virgula)</label>
    <input [(ngModel)]="infrastructura" type="text" class="gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1">
  </div>
  <div class="inputs-label-container w-100 d-flex flex-column mb-3">
    <label class="inputs-label">Limbele de instruire (Indicati elementele prin virgula)</label>
    <input [(ngModel)]="limbeDeInstruire" type="text" class="gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1">
  </div>
  <div class="inputs-label-container w-100 d-flex flex-column mb-3">
    <label class="inputs-label">Numar de contact</label>
    <input [(ngModel)]="numarDeContact" type="text" class="gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1">
  </div>
  <div class="inputs-label-container w-100 d-flex flex-column mb-3">
    <label class="inputs-label">Adresele de deservire (Fiecare adresa din rand nou)</label>
    <textarea [(ngModel)]="adreseleDeDeservire" class="textarea-input gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1"></textarea>
  </div>



  <span class="mt-3 mb-3 no-acc-text d-flex align-items-center"><input [(ngModel)]="confirmare" type="checkbox" class="mr-3">Confirmati adresele de deservire .</span>
  <button (click)="salvareaInformatiei()" class="button green main-log-button mt-3">Pasul urmator</button>
</section>

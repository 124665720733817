import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {GradiniteService} from '../services/gradinite-service';
import {ParinteCommunication} from '../services/parinte-communication';
import {CereriService} from '../services/cereri-service';
import {GradinitaFullModel} from '../models/gradinita/gradinita-full-model';
import {CazPrioritateModel} from '../models/main/submodel/caz-prioritate-model';
import {MainService} from '../services/main-service';

@Component({
  selector: 'app-depune-cererea',
  templateUrl: './depune-cererea.component.html',
  styleUrls: ['./depune-cererea.component.css']
})
export class DepuneCerereaComponent implements OnInit {

  @ViewChild('file', {static: true}) file: ElementRef;

  constructor(private router: Router, private gradiniteService: GradiniteService, private cereriService: CereriService,
              private mainService: MainService, private parinteCommunication: ParinteCommunication) {
    if(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza') == null) {
      this.router.navigateByUrl('/');
    }
    if(this.parinteCommunication.gradinita_id_selected == 0) {
      this.router.navigateByUrl('/parinte-main');
    }
  }

  ngOnInit() {
    this.currentTime = new Date().getTime();

    this.getCazuriPrioritate();
    this.getDocumenteNecesare();

    this.getGradinitaDetails();
  }

  currentTime: number = 0;

  cerereProcessing: boolean = false;

  gradinitaDetails: GradinitaFullModel = new GradinitaFullModel();

  cazuriNecesare: CazPrioritateModel[] = [];

  documenteNecesare: string[] = [];

  /** CERERE FIELDS **/
  limbaSelectata: number = 0;
  copilSexul: number = 0;

  parinteIdnp: string = '';
  parinteNume: string = '';
  parinteNrTelefon: string = '';
  parinteEmail: string = '';
  parinteLocalitate: string = '';
  parinteAdresa: string = '';

  copilIdnp: string = '';
  copilNume: string = '';
  copilAnulNasterii: string = '';

  documenteleIncarcate: File[] = [];

  /**  ###########  **/

  getGradinitaDetails() {
    this.gradiniteService.informatie_deplina(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'),
      String(this.parinteCommunication.gradinita_id_selected)).subscribe((data) => {
        if(data.status) {
          this.gradinitaDetails = data.gradinita;
        }
    }, error => {
        console.log(error);
    })
  }

  getCazuriPrioritate() {
    this.mainService.getCazPrioritate().subscribe((data) => {
      if(data.status) {
        const cazuri = data.cazuri;
        this.cazuriNecesare = [];

        for(let i = 0; i < cazuri.length; i++) {
          const caz = new CazPrioritateModel();
          caz.caz = cazuri[i];
          this.cazuriNecesare.push(caz);
        }
      }
    }, error => {
      console.log(error);
    })
  }

  getDocumenteNecesare() {
    this.mainService.getDocumenteNecesare().subscribe((data) => {
      if(data.status) {
        this.documenteNecesare = data.documente;
      }
    }, error => {
      console.log(error);
    })
  }

  incarcaDocument(event) {
    let file = <File> event.target.files[0];

    this.documenteleIncarcate.push(file);

    this.file.nativeElement.value = '';
  }

  stergeDocumentul(pozitia: number) {
    this.documenteleIncarcate.splice(pozitia, 1);
  }

  inapoiLaGradinite(){
    this.router.navigateByUrl('/parinte-main');
  }

  validateInputs() : boolean {

    if(this.parinteIdnp.length == 0 || this.parinteNume.length == 0 || this.parinteNrTelefon.length == 0 || this.parinteEmail.length == 0 ||
      this.parinteLocalitate.length == 0 || this.parinteAdresa.length == 0 || this.copilIdnp.length == 0 || this.copilNume.length == 0 ||
    this. copilAnulNasterii.length == 0) {
      alert('Toate cimpurile sunt obligatorii');

      return false;
    }
    else if (!this.validateEmail(this.parinteEmail)) {
      alert('Introduceti o adresa de email valida');

      return false;
    }
    else{
      return true;
    }
  }

  depuneCererea() {
    const check = this.validateInputs();

    if(check) {

      if(!this.cerereProcessing) {
        this.cerereProcessing = true;

        let cazuriString = '';

        for (let i = 0; i < this.cazuriNecesare.length; i++) {
          if (this.cazuriNecesare[i].selectat) {
            if (cazuriString.length != 0) {
              cazuriString += ',';
            }
            cazuriString = cazuriString + this.cazuriNecesare[i].caz;
          }
        }

        let copilSex = 'Masculin';

        if (this.copilSexul == 1) {
          copilSex = 'Feminin';
        }

        this.cereriService.depune_cererea(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'), this.documenteleIncarcate,
          String(this.gradinitaDetails.id), this.gradinitaDetails.limba_de_instruire[this.limbaSelectata], this.parinteIdnp, this.parinteNume,
          this.parinteNrTelefon, this.parinteEmail, this.parinteLocalitate, this.parinteAdresa, this.copilIdnp, this.copilNume,
          this.copilAnulNasterii, copilSex, cazuriString).subscribe((data) => {
          if (data.status) {
            this.router.navigateByUrl('/cerere-succes');
          } else {
            this.router.navigateByUrl('/cerere-eroare');
          }
        }, error => {
            this.cerereProcessing = false;
          console.log(error);
        });
      }
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}

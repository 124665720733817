<div class="customer-main-container d-flex flex-column w-100">
  <div class="view-tabs-back-container d-flex align-items-end justify-content-between flex-wrap">
    <div class="view-tabs-back-min-container" style="min-height: 1.5rem !important;">
      <ul class="back-cont d-flex align-items-end">
        <li (click)="inapoiLaGradinite()"><img class="back-arrow-img" src="../../assets/mainp/back%201.png"> Inapoi</li>

      </ul>
    </div>
  </div>



  <div class="show-grad-container-main d-flex flex-column w-100">

    <div class="grad-container w-100 mt-2 mb-3">
      <div class="upper-grad-min-container d-flex justify-content-between flex-wrap">
        <div class="grad-gen-info-container d-flex mb-1">
          <img src="../../assets/mainp/school%201.png" class="gen-left-img">
          <div class="gen-grad-info-container d-flex ml-3 flex-column">
            <span class="grad-name-container mb-3">{{ gradinitaDetails.nume }}</span>
            <span class="grad-sub-info-container">Termen limita de primire a cererilor : <span [ngClass]="gradinitaDetails.termen_limita >= currentTime && gradinitaDetails.locuri_disponibile > 0  ? 'available' : 'unavailable'">{{  gradinitaDetails.termen_limita | date: 'dd/MM/yyyy'  }}</span></span>
            <span class="grad-sub-info-container">Locuri libere : <span [ngClass]="gradinitaDetails.termen_limita >= currentTime && gradinitaDetails.locuri_disponibile > 0  ? 'available' : 'unavailable'">{{ gradinitaDetails.locuri_disponibile }}</span></span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column">
        <span class="address-header mb-4">Limba de instruire</span>
        <div class="inputs-label-container d-flex flex-column w-100">
          <select [(ngModel)]="limbaSelectata" class="custom-select mt-4" style="margin-left: 1rem;">
            <option *ngFor="let limba of gradinitaDetails.limba_de_instruire; let i = index;" [value]="i">{{ limba }}</option>
          </select>
        </div>
      </div>
      <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column info-parinte-copil-container">
        <span class="address-header">Informatie Parinte</span>

        <div class="d-flex flex-wrap  w-100 mt-4">
          <div class="inputs-label-container d-flex flex-column w-100">
            <label class="inputs-label">IDNP</label>
            <input [(ngModel)]="parinteIdnp" class="gen-input-white-bkg text-left" type="number">
          </div>
          <div class="inputs-label-container d-flex flex-column w-100">
            <label class="inputs-label">Nume/Prenume</label>
            <input [(ngModel)]="parinteNume" class="gen-input-white-bkg text-left" type="text">
          </div>
          <div class="inputs-label-container d-flex flex-column w-100">
            <label class="inputs-label">Nr. Telefon</label>
            <input [(ngModel)]="parinteNrTelefon" class="gen-input-white-bkg text-left" type="phone">
          </div>
          <div class="inputs-label-container d-flex flex-column w-100">
            <label class="inputs-label">Email</label>
            <input [(ngModel)]="parinteEmail" class="gen-input-white-bkg text-left" type="email">
          </div>
          <div class="inputs-label-container d-flex flex-column w-100">
            <label class="inputs-label">Localitate</label>
            <input [(ngModel)]="parinteLocalitate" class="gen-input-white-bkg text-left" type="text">
          </div>
          <div class="inputs-label-container d-flex flex-column w-100">
            <label class="inputs-label">Adresa</label>
            <input [(ngModel)]="parinteAdresa" class="gen-input-white-bkg text-left" type="text">
          </div>
        </div>

      </div>
      <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column info-parinte-copil-container">
        <span class="address-header">Informatie Copil</span>

        <div class="d-flex flex-wrap  w-100 mt-4">
          <div class="inputs-label-container d-flex flex-column w-100">
            <label class="inputs-label">IDNP</label>
            <input [(ngModel)]="copilIdnp" class="gen-input-white-bkg text-left" type="number">
          </div>
          <div class="inputs-label-container d-flex flex-column w-100">
            <label class="inputs-label">Nume/Prenume</label>
            <input [(ngModel)]="copilNume" class="gen-input-white-bkg text-left" type="text">
          </div>
          <div class="inputs-label-container d-flex flex-column w-100">
            <label class="inputs-label">Anul nasterii</label>
            <input [(ngModel)]="copilAnulNasterii" class="gen-input-white-bkg text-left" type="number">
          </div>
          <div class="inputs-label-container d-flex flex-column w-100">
            <label class="inputs-label">Sexul</label>
            <select [(ngModel)]="copilSexul" class="custom-select" style="margin-top: 0px !important;">
              <option value="0">Masculin</option>
              <option value="1">Feminin</option>
            </select>
          </div>
        </div>

      </div>
      <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column caz-prior">
        <span class="address-header mb-4">Caz cu prioritate</span>

        <ul class="caz-prior-list">
          <li *ngFor="let caz of cazuriNecesare"><input [(ngModel)]="caz.selectat" type="checkbox">{{ caz.caz }}</li>
        </ul>
      </div>
      <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column caz-prior">
        <span class="address-header mb-4">Documente necesare</span>

        <ul class="caz-prior-list">
          <li *ngFor="let document of documenteNecesare; let i = index">{{ i + 1 }}. {{ document }}</li>
          <li>&nbsp; * In cazul daca selectati unul din cazurile cu prioritate , confirmati acest lucru prin copia documentului ce da dovada de acestea.</li>

        </ul>
      </div>
      <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column caz-prior">
        <span class="address-header mb-4">Documentele incarcate</span>

        <ul class="caz-prior-list">
          <li *ngFor="let document of documenteleIncarcate; let position = index" [ngClass]="document.remove ? 'd-none' : ''"><div class="address-bulina"></div>{{ document.document_name }} <div (click)="stergeDocumentul(position)" class="delete-document"></div></li>
        </ul>
        <input (change)="incarcaDocument($event)" class="d-none" type="file" #file>
        <button (click)="file.click()" class="button blue" style="position: relative;padding-left: 4rem;display: flex;align-items: center;"><img style="position: absolute; left: 10px;" src="../../assets/mainp/upload%20(1)%201.png">Incarca document</button>
      </div>
      <button (click)="modificareCerere()" class="button green mt-5 w-100" style="max-width: 353px;">Salveaza modificarile</button>
    </div>




  </div>

</div>

import {Injectable} from '@angular/core';
import {BASE_URL} from '../constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SectoareResponse} from '../models/gradinita/sectoare-response';
import {RegistrulPublicResponse} from '../models/cereri/registrul-public-response';
import {CererileMeleResponse} from '../models/cereri/cererile-mele-response';
import {DocumenteleAtasateResponse} from '../models/cereri/documentele-atasate-response';
import {PostResponse} from '../models/post-response';
import {CerereDetaliiResponse} from '../models/cereri/cerere-detalii-response';

@Injectable({
  providedIn: 'root'
})
export class CereriService {

  baseCereriUrl = BASE_URL + '/cereri/';

  /** URLS **/

  cerere_detaliiUrl = 'cerere_detalii';
  registrul_publicUrl = 'registrul_public';
  cererile_meleUrl = 'cererile_mele';
  download_documentulUrl = 'download_documentul';
  documentele_atasateUrl = 'documentele_atasate';
  cererile_depuseUrl = 'cererile_depuse';
  cererile_transferateUrl = 'cererile_transferate';
  depune_cerereaUrl = 'depune_cererea';
  editare_cerereaUrl = 'editare_cererea';
  retrage_cerereaUrl = 'retrage_cererea';
  upload_documenteUrl = 'upload_documente';
  respinge_cerereaUrl = 'respinge_cererea';
  transfer_cerereaUrl = 'transfer_cererea';
  solicita_acteUrl = 'solicita_acte';
  inmatriculare_aplicareUrl = 'inmatriculare_aplicare';
  inmatriculareUrl = 'inmatriculare';
  sterge_documentulUrl = 'sterge_documentul';

  constructor(private http: HttpClient) {}

  registrul_public(token: string, gradinita_id: string) {
    const fullUrl = this.baseCereriUrl + this.registrul_publicUrl + '?gradinita_id=' + gradinita_id;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.get<RegistrulPublicResponse>(fullUrl, httpOptionsToken);
  }

  cerere_detalii(token: string, cerere_id: string) {
    const fullUrl = this.baseCereriUrl + this.cerere_detaliiUrl + "?cerere_id=" + cerere_id;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.get<CerereDetaliiResponse>(fullUrl, httpOptionsToken);
  }

  cererile_mele(token: string, statut: string) {
    const fullUrl = this.baseCereriUrl + this.cererile_meleUrl + "?statut=" + statut;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.get<CererileMeleResponse>(fullUrl, httpOptionsToken);
  }

  download_documentul(token: string, document_id: string) {
    const fullUrl = this.baseCereriUrl + this.download_documentulUrl + "?document_id=" + document_id;

    return this.http.get(fullUrl, {responseType: 'blob', headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + token
    })});
  }

  documentele_atasate(token: string, cerere_id: string) {
    const fullUrl = this.baseCereriUrl + this.documentele_atasateUrl + "?cerere_id=" + cerere_id;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.get<DocumenteleAtasateResponse>(fullUrl, httpOptionsToken);
  }

  cererile_depuse(token: string, statut: string, an: string, query: string) {
    const fullUrl = this.baseCereriUrl + this.cererile_depuseUrl + "?statut=" + statut + "&an=" + an + "&query=" + query;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.get<CererileMeleResponse>(fullUrl, httpOptionsToken);
  }

  cererile_transferate(token: string, statut: string, an: string, query: string) {
    const fullUrl = this.baseCereriUrl + this.cererile_transferateUrl + "?statut=" + statut + "&an=" + an + "&query=" + query;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.get<CererileMeleResponse>(fullUrl, httpOptionsToken);
  }

  depune_cererea(token: string, documente: File[], gradinita_id: string, limba_instruire: string, parinte_idnp: string,
                 parinte_nume: string, parinte_telefon: string, parinte_email: string, parinte_localitate: string, parinte_adresa: string,
                 copil_idnp: string, copil_prenume: string, copil_anul_nasterii: string, copil_sexul: string, caz_prioritate: string){
    const fullurl = this.baseCereriUrl + this.depune_cerereaUrl;

    const formData = new FormData();
    for(let i = 0; i < documente.length; i++){
      formData.append("files", documente[i], documente[i].name);
    }
    formData.append('gradinita_id', gradinita_id);
    formData.append('limba_instruire', limba_instruire);
    formData.append('parinte_idnp', parinte_idnp);
    formData.append('parinte_nume', parinte_nume);
    formData.append('parinte_telefon', parinte_telefon);
    formData.append('parinte_email', parinte_email);
    formData.append('parinte_localitate', parinte_localitate);
    formData.append('parinte_adresa', parinte_adresa);
    formData.append('copil_idnp', copil_idnp);
    formData.append('copil_prenume', copil_prenume);
    formData.append('copil_anul_nasterii', copil_anul_nasterii);
    formData.append('copil_sexul', copil_sexul);
    formData.append('caz_prioritate', caz_prioritate);


    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.post<PostResponse>(fullurl, formData, httpOptionsToken);

  }

  editare_cererea(token: string, cerere_id: number, limba_instruire: string, parinte_idnp: string, parinte_nume: string,
                  parinte_telefon: string, parinte_email: string, parinte_localitate: string, parinte_adresa: string, copil_idnp: string,
                  copil_prenume: string, copil_anul_nasterii: string, copil_sexul: string, caz_prioritate: string){
    const fullUrl = this.baseCereriUrl + this.editare_cerereaUrl;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    const body = {
      cerere_id,
      limba_instruire,
      parinte_idnp,
      parinte_nume,
      parinte_telefon,
      parinte_email,
      parinte_localitate,
      parinte_adresa,
      copil_idnp,
      copil_prenume,
      copil_anul_nasterii,
      copil_sexul,
      caz_prioritate
    };

    return this.http.post<PostResponse>(fullUrl, body, httpOptionsToken);
  }

  retrage_cererea(token: string, cerere_id: string){
    const fullUrl = this.baseCereriUrl + this.retrage_cerereaUrl;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    const body = {
      cerere_id
    };

    return this.http.post<PostResponse>(fullUrl, body, httpOptionsToken);
  }

  upload_documente(token: string, documentul: File, cerere_id: string){

    const fullurl = this.baseCereriUrl + this.upload_documenteUrl;

    const formData = new FormData();
    if(documentul != null){
      formData.append("files", documentul, documentul.name);
    }
    formData.append('cerere_id', cerere_id);


    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.post<PostResponse>(fullurl, formData, httpOptionsToken);
  }

  sterge_documentul(token: string, cerere_id: string, document_id: string) {
    const fullUrl = this.baseCereriUrl + this.sterge_documentulUrl;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    const body = {
      cerere_id,
      document_id
    };

    return this.http.post<PostResponse>(fullUrl, body, httpOptionsToken);
  }

  respinge_cererea(token: string, cerere_id: string, comentariu: string, motiv: string){
    const fullUrl = this.baseCereriUrl + this.respinge_cerereaUrl;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    const body = {
      cerere_id,
      comentariu,
      motiv
    };

    return this.http.post<PostResponse>(fullUrl, body, httpOptionsToken);
  }

  transfer_cererea(token: string, cerere_id: string, comentariu: string, motiv: string, to_gradinita_id: string){
    const fullUrl = this.baseCereriUrl + this.transfer_cerereaUrl;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    const body = {
      cerere_id,
      comentariu,
      motiv,
      to_gradinita_id
    };

    return this.http.post<PostResponse>(fullUrl, body, httpOptionsToken);
  }

  solicita_acte(token: string, cerere_id: string, comentariu: string){
    const fullUrl = this.baseCereriUrl + this.solicita_acteUrl;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    const body = {
      cerere_id,
      comentariu
    };

    return this.http.post<PostResponse>(fullUrl, body, httpOptionsToken);
  }

  inmatriculare_aplicare(token: string, cerere_id: string, comentariu: string){
    const fullUrl = this.baseCereriUrl + this.inmatriculare_aplicareUrl;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    const body = {
      cerere_id,
      comentariu
    };

    return this.http.post<SectoareResponse>(fullUrl, body, httpOptionsToken);
  }

  inmatriculare(token: string, cerere_id: string){
    const fullUrl = this.baseCereriUrl + this.inmatriculareUrl;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    const body = {
      cerere_id
    };

    return this.http.post<PostResponse>(fullUrl, body, httpOptionsToken);
  }
}

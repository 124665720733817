import {Injectable} from '@angular/core';
import {BASE_URL} from '../constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PostResponse} from '../models/post-response';
import {SectoareResponse} from '../models/gradinita/sectoare-response';
import {GradinitaSectorResponse} from '../models/gradinita/gradinita-sector-response';
import {InformatieDeplinaResponse} from '../models/gradinita/informatie-deplina-response';
import {InformatieCompletataResponse} from '../models/gradinita/informatie-completata-response';


@Injectable({
  providedIn: 'root'
})
export class GradiniteService {

  baseGradinitaUrl = BASE_URL + '/gradinite/';

  /** URLS **/

  sectoareUrl = 'sectoare';
  gradinite_sectorUrl = 'gradinite_sector';
  informatie_deplinaUrl = 'informatie_deplina';
  informatie_completataUrl = 'informatie_completata';
  editare_informatieUrl = 'editare_informatie';
  gradinita_informatie_completaUrl = 'gradinita_informatie_completa';

  constructor(private http: HttpClient) {}

  sectoare(token: string) {
    const fullUrl = this.baseGradinitaUrl + this.sectoareUrl;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.get<SectoareResponse>(fullUrl, httpOptionsToken);
  }

  gradinite_sector(token: string, sector: string, query: string) {
    const fullUrl = this.baseGradinitaUrl + this.gradinite_sectorUrl + '?sector=' + sector + '&query=' + query;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.get<GradinitaSectorResponse>(fullUrl, httpOptionsToken);
  }

  gradinite_sector_director(token: string, sector: string, query: string, user_id: string) {
    const fullUrl = this.baseGradinitaUrl + this.gradinite_sectorUrl + '?sector=' + sector + '&query=' + query + '&user_id=' + user_id;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.get<GradinitaSectorResponse>(fullUrl, httpOptionsToken);
  }

  informatie_deplina(token: string, gradinita_id: string) {
    const fullUrl = this.baseGradinitaUrl + this.informatie_deplinaUrl + '?gradinita_id=' + gradinita_id;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.get<InformatieDeplinaResponse>(fullUrl, httpOptionsToken);
  }

  gradinita_informatie_completa(token: string) {
    const fullUrl = this.baseGradinitaUrl + this.gradinita_informatie_completaUrl;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.get<InformatieDeplinaResponse>(fullUrl, httpOptionsToken);
  }

  informatie_completata(token: string) {
    const fullUrl = this.baseGradinitaUrl + this.informatie_completataUrl;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    return this.http.get<InformatieCompletataResponse>(fullUrl, httpOptionsToken);
  }

  editare_informatie(token: string, data_limita: number, numar_locuri_disponibile: number, numar_cadre_didactice: number,
                     numar_diplome: number, email_contact: string, activitati: string, infrastructura: string, limbe_de_instruire: string,
                     numar_contact: string, adrese_deservire: string) {
    const fullUrl = this.baseGradinitaUrl + this.editare_informatieUrl;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    const body = {
      data_limita,
      numar_locuri_disponibile,
      numar_cadre_didactice,
      numar_diplome,
      email_contact,
      activitati,
      infrastructura,
      limbe_de_instruire,
      numar_contact,
      adrese_deservire
    };

    return this.http.post<PostResponse>(fullUrl, body, httpOptionsToken);
  }
}

<div class="customer-main-container d-flex flex-column w-100">
  <div class="view-tabs-back-container d-flex align-items-end justify-content-between flex-wrap">
    <div class="view-tabs-back-min-container" style="min-height: 1.5rem !important;">
      <ul class="back-cont d-flex align-items-end">
        <li (click)="inapoiLaLista()"><img class="back-arrow-img" src="../../assets/mainp/back%201.png"> Inapoi la lista gradinitelor</li>

      </ul>
    </div>
  </div>



  <div class="show-grad-container-main d-flex flex-column w-100">

    <div class="grad-container w-100 mt-2 mb-3">
      <div class="upper-grad-min-container d-flex justify-content-between flex-wrap">
        <div class="grad-gen-info-container d-flex mb-1">
          <img src="../../assets/mainp/school%201.png" class="gen-left-img">
          <div class="gen-grad-info-container d-flex ml-3 flex-column">
            <span class="grad-name-container mb-3">{{ gradinitaDetails.nume }}</span>
            <span class="grad-sub-info-container">Termen limita de primire a cererilor : <span [ngClass]="gradinitaDetails.termen_limita >= currentTime && gradinitaDetails.locuri_disponibile > 0 ? 'available' : 'unavailable'">{{ gradinitaDetails.termen_limita | date: 'dd/MM/yyyy' }}</span></span>
            <span class="grad-sub-info-container">Locuri libere : <span [ngClass]="gradinitaDetails.termen_limita >= currentTime && gradinitaDetails.locuri_disponibile > 0 ? 'available' : 'unavailable'">{{ gradinitaDetails.locuri_disponibile }}</span></span>
          </div>
        </div>
        <div class="grad-options-container d-flex flex-column justify-content-between align-items-center">
          <button *ngIf="!gradinitaDetails.cerere_depuse" [disabled]="gradinitaDetails.termen_limita < currentTime || gradinitaDetails.locuri_disponibile <= 0 " [ngClass]="gradinitaDetails.termen_limita < currentTime || gradinitaDetails.locuri_disponibile <= 0 ? 'gray' : 'green'" (click)="cerereNoua()" class="button green">Depune cererea</button>
          <button *ngIf="gradinitaDetails.cerere_depuse" (click)="openCerereDepusa()" class="button orange d-none">Cerere depusa</button>
          <button (click)="deschideRegistrulPublic()" class="button blue mt-3">Registru public</button>
        </div>
      </div>
    </div>



    <div class="grad-container w-100 mt-2 mb-3">
      <div class="d-flex flex-wrap align-items-end justify-content-between ">
        <div class=" d-flex flex-wrap justify-content-between w-100">
          <div class="more-info-item fixed-width d-flex align-items-end ">
            <img class="more-info-img" src="../../assets/mainp/city%201.png">
            <span class="more-info-text ml-3"><span class="available"></span>{{ gradinitaDetails.sector_name }}</span>
          </div>
          <div class="more-info-item fixed-width d-flex align-items-end ">
            <img class="more-info-img" src="../../assets/mainp/maps-and-flags.png">
            <span class="more-info-text ml-3"><span class="available"></span>{{ gradinitaDetails.adresa_gradinita }}</span>
          </div>
          <div class="more-info-item fixed-width d-flex align-items-end ">
            <img class="more-info-img" src="../../assets/mainp/students%201.png">
            <span class="more-info-text ml-3"><span [ngClass]="gradinitaDetails.termen_limita >= currentTime && gradinitaDetails.locuri_disponibile > 0 ? 'available' : 'unavailable'">{{ gradinitaDetails.locuri_disponibile }} </span>locuri libere</span>
          </div>
        </div>
      </div>


    </div>

    <div class="grad-container w-100 mt-2 mb-3">
      <div class="d-flex flex-wrap align-items-end justify-content-between ">
        <div class=" d-flex justify-content-between flex-wrap w-100">
          <div class="more-info-item fixed-width d-flex align-items-end ">
            <img class="more-info-img" src="../../assets/mainp/teacher%20(2)%201.png">
            <span class="more-info-text ml-3"><span class="available"></span>{{ gradinitaDetails.numar_cadre_didactice }} cadre didactive</span>
          </div>
          <div class="more-info-item fixed-width d-flex align-items-end ">
            <img class="more-info-img" src="../../assets/mainp/certificate%201.png">
            <span class="more-info-text ml-3"><span class="available"></span>{{ gradinitaDetails.numar_diplome }} diplome</span>
          </div>
          <div class="more-info-item fixed-width d-flex align-items-end ">
            <img class="more-info-img" src="../../assets/mainp/language%201.png">
            <span class="more-info-text ml-3"><span class="available"></span>{{ limbeString }}</span>
          </div>
        </div>
      </div>


    </div>


    <div class="grad-container w-100 mt-2 mb-3">
      <div class="d-flex flex-wrap mt-2 pt-2 align-items-end justify-content-between">
        <div class="address-container d-flex flex-column">
          <span class="address-header">Infrastructura</span>
          <ul class="address-list more-columns mt-4">
            <li *ngFor="let infrastructura of gradinitaDetails.infrastructura" class="address-item"><div class="address-bulina"></div>{{ infrastructura }}</li>

          </ul>
        </div>
      </div>


    </div>
    <div class="grad-container w-100 mt-2 mb-3">
      <div class="d-flex flex-wrap mt-2 pt-2 align-items-end justify-content-between">
        <div class="address-container d-flex flex-column">
          <span class="address-header">Activitati</span>
          <ul class="address-list more-columns mt-4">
            <li *ngFor="let activitati of gradinitaDetails.activitati" class="address-item"><div class="address-bulina"></div>{{ activitati }}</li>

          </ul>
        </div>
      </div>


    </div>
    <div class="grad-container w-100 mt-2 mb-3">
      <div class="d-flex flex-wrap mt-2 pt-2 align-items-end justify-content-between">
        <div class="address-container d-flex flex-column">
          <span class="address-header">Adresele de deservire a gradinitei</span>
          <ul class="address-list more-columns mt-4">
            <li *ngFor="let adresele of gradinitaDetails.adrese" class="address-item"><div class="address-bulina"></div>{{ adresele }}</li>

          </ul>
        </div>
      </div>


    </div>



  </div>

</div>

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cerere-depusa-success',
  templateUrl: './cerere-depusa-success.component.html',
  styleUrls: ['./cerere-depusa-success.component.css']
})
export class CerereDepusaSuccessComponent implements OnInit {

  constructor(private router: Router) {
    if(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza') == null) {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit(): void {
  }

  listaDeGradinite() {
    this.router.navigateByUrl('/parinte-main');
  }
}

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ParinteCommunication} from '../services/parinte-communication';
import {CereriService} from '../services/cereri-service';
import {CerereShortModel} from '../models/cereri/submodel/cerere-short-model';

@Component({
  selector: 'app-reg-public',
  templateUrl: './reg-public.component.html',
  styleUrls: ['./reg-public.component.css']
})
export class RegPublicComponent implements OnInit {

  constructor(private router: Router, private cereriService: CereriService, private parinteCommunication: ParinteCommunication) {
    if(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza') == null) {
      this.router.navigateByUrl('/');
    }

    if(this.parinteCommunication.gradinita_id_selected == 0) {
      this.router.navigateByUrl('/parinte-main')
    }

    this.gradinitaId = this.parinteCommunication.gradinita_id_selected;
    this.gradinitaNume = this.parinteCommunication.gradinita_nume;
  }

  gradinitaId = 0;
  gradinitaNume = '';

  cereriList: CerereShortModel[] = [];

  ngOnInit() {
    this.getRegistrulPublic();
  }

  inapoiLaGradinita() {
    this.router.navigateByUrl('/parinte-main-detalii-grad');
  }

  getRegistrulPublic() {
    this.cereriService.registrul_public(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'), String(this.gradinitaId)).subscribe((data) => {
      if(data.status) {
        this.cereriList = data.cereri;
      }
    }, error => {
      console.log(error);
    })
  }

}

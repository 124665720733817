import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthorizationService} from '../services/authorization-service';
import {MainCommunication} from '../services/main-communication';

@Component({
  selector: 'app-log-dir-activate',
  templateUrl: './log-dir-activate.component.html',
  styleUrls: ['./log-dir-activate.component.css']
})
export class LogDirActivateComponent implements OnInit {

  constructor(private router: Router, private autorizareService: AuthorizationService, private mainCommunication: MainCommunication) { }

  ngOnInit() {
  }

  codAutentificare: string;

  autentificare() {
    if(this.codAutentificare.length == 0) {
      alert('Cimpul cu Codul de activare este obligatoriu');
    }
    else{
      this.autorizareService.activare_cont(this.codAutentificare).subscribe((data) => {
        if(data.status) {
          this.mainCommunication.saveToken(data.token);

          this.router.navigateByUrl('/logare-director-creare');
        }
        else {
          alert('Codul de activare este gresit');
        }
      }, error => {
        alert('Codul de activare este gresit');
      })
    }
  }

}

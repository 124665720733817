import {Injectable} from '@angular/core';
import {GradinitaModel} from '../models/gradinita/submodel/gradinita-model';

@Injectable({
  providedIn: 'root'
})
export class ParinteCommunication {

  gradinita_id_selected: number = 0;
  gradinita_nume: string = '';

  cerere_id_selected: number = 0;

  setGradinitaIdSelected(gradinita_id: number) {
    this.gradinita_id_selected = gradinita_id;
  }

  setGradinitaNume(gradinita_nume: string) {
    this.gradinita_nume = gradinita_nume;
  }

  setCerereIdSelectd(cerere_id: number) {
    this.cerere_id_selected = cerere_id;
  }
}

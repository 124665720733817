import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {CereriService} from '../services/cereri-service';
import {ParinteCommunication} from '../services/parinte-communication';

@Component({
  selector: 'app-retrage-cererea-confirmare',
  templateUrl: './retrage-cererea-confirmare.component.html',
  styleUrls: ['./retrage-cererea-confirmare.component.css']
})
export class RetrageCerereaConfirmareComponent implements OnInit {

  constructor(private router: Router, private cereriService: CereriService, private parinteCommunication: ParinteCommunication) {
    if(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza') == null) {
      this.router.navigateByUrl('/');
    }

    if(this.parinteCommunication.cerere_id_selected == 0) {
      this.router.navigateByUrl('/cereri-depuse')
    }
  }

  ngOnInit(): void {
  }

  retrageCererea() {
    this.cereriService.retrage_cererea(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'), String(this.parinteCommunication.cerere_id_selected)).subscribe((data) => {
      if(data.status) {
        this.router.navigateByUrl('/cereri-depuse');
      }
    }, error => {
      console.log(error);
    })
  }

}

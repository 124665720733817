import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainCommunication {

  token: string = '';

  selectedYear : number = 0;

  private emitDespreNoi = new Subject<null>();
  private emitInstructiuniDeAplicare = new Subject<null>();
  private emitLoggedChanged = new Subject<null>();
  private emitChangedYear = new Subject<null>();

  despreNoi$ = this.emitDespreNoi.asObservable();
  instructiuniDeAplicare$ = this.emitInstructiuniDeAplicare.asObservable();
  loggedChanged$ = this.emitLoggedChanged.asObservable();
  changedYear$ = this.emitChangedYear.asObservable();

  loggedChanged() {
    this.emitLoggedChanged.next();
  }

  despreNoi() {
    this.emitDespreNoi.next();
  }

  instructiuniDeAplicare() {
    this.emitInstructiuniDeAplicare.next();
  }

  saveToken(token: string) {
    this.token = token;
  }

  changedYear(selectedYear: number) {
    this.selectedYear = selectedYear;
    this.emitChangedYear.next();
  }
}

import {Injectable} from '@angular/core';
import {BASE_URL} from '../constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PostResponse} from '../models/post-response';
import {LogareClientResponse} from '../models/authorization/logare-client-response';
import {ActivareContResponse} from '../models/authorization/activare-cont-response';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  baseAuthorizationUrl = BASE_URL + '/autorizare/';

  httpOptions = {};

  /** URLS **/

  inregistrare_clientUrl = 'inregistrare_client';
  logare_clientUrl = 'logare_client';
  logare_directorUrl = 'logare_director';
  activare_contUrl = 'activare_cont';
  inregistrare_cont_directorUrl = 'inregistrare_cont_director';

  constructor(private http: HttpClient) {}

  inregistrare_client(email: string) {
    const fullUrl = this.baseAuthorizationUrl + this.inregistrare_clientUrl;

    const body = {
      email
    };

    return this.http.post<PostResponse>(fullUrl, body, this.httpOptions);
  }

  logare_client(cod: string) {
    const fullUrl = this.baseAuthorizationUrl + this.logare_clientUrl;

    const body = {
      cod
    };

    return this.http.post<LogareClientResponse>(fullUrl, body, this.httpOptions);
  }

  logare_director(email: string, password: string, code_last_three: string) {
    const fullUrl = this.baseAuthorizationUrl + this.logare_directorUrl;

    const body = {
      email,
      password,
      code_last_three
    };

    return this.http.post<LogareClientResponse>(fullUrl, body, this.httpOptions);
  }

  activare_cont(cod: string) {
    const fullUrl = this.baseAuthorizationUrl + this.activare_contUrl;

    const body = {
      cod
    };

    return this.http.post<ActivareContResponse>(fullUrl, body, this.httpOptions);
  }

  inregistrare_cont_director(email: string, password: string, nume: string, token: string) {
    const fullUrl = this.baseAuthorizationUrl + this.inregistrare_cont_directorUrl;

    const httpOptionsToken = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };

    const body = {
      email,
      password,
      nume
    };

    return this.http.post<PostResponse>(fullUrl, body, httpOptionsToken);
  }
}

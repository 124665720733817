<div class="footer-container">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">

    <div class=" navbar-collapse" id="navbarNavDropdown2">
      <ul class="navbar-nav">
        <li [ngClass]="router.url == '/' ? 'active' : ''" (click)="goToAcasa()" class="nav-item">
          <span class="nav-link">Acasă</span>
        </li>

        <li *ngIf="logged" [ngClass]="(router.url == '/parinte-main' && loggedType == 0) || (router.url == '/lista-aplicari' && loggedType == 999) ? 'active' : ''" (click)="goToProfil()" class="nav-item">
          <span class="nav-link">Profil</span>
        </li>

        <li class="nav-item dropdown">
        <span class="nav-link dropdown-toggle" id="navbarDropdownMenuLink2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Informații utile
        </span>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink2">
            <span (click)="goToDespreNoi()" class="dropdown-item">Despre noi</span>
            <span (click)="goToIntrebariFrecvente()" class="dropdown-item">Întrebări frecvente</span>
            <span (click)="goToInstructiuniDeAplicare()" class="dropdown-item">Instrucțiuni de aplicare</span>
          </div>
        </li>

        <!--      <li class="nav-item">-->
        <!--        <span class="nav-link">Despre noi</span>-->
        <!--      </li>-->
        <!--      <li class="nav-item">-->
        <!--        <span class="nav-link">Întrebări frecvente</span>-->
        <!--      </li>-->
        <!--      <li class="nav-item">-->
        <!--        <span class="nav-link">Instrucțiuni de aplicare</span>-->
        <!--      </li>-->
        <li (click)="goToRegulament()" class="nav-item">
          <span class="nav-link">Regulament</span>
        </li>
        <li *ngIf="!logged" (click)="goToAutentificare()" [ngClass]="router.url == '/logare-parinte' ? 'active' : ''" class="nav-item ">
          <span class="nav-link">Autentificare</span>
        </li>
        <li *ngIf="!logged" (click)="goToInregistrare()" [ngClass]="router.url == '/inregistrare-parinte' ? 'active' : ''" class="nav-item ">
          <span class="nav-link">Înregistrare</span>
        </li>
        <!--   logged-hide si logged-show sunt clasele pentru a ascunde si arata butoanele in dependenta de starea de logare.   -->
        <li *ngIf="logged" (click)="logOut()" class="nav-item ">
          <span class="nav-link">Ieșire</span>
        </li>
        <li class="nav-item dropdown">
        <span style="color:var(--green-color) !important;" class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Ro
        </span>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <span class="dropdown-item active">Ro</span>
            <span class="dropdown-item">Py</span>
          </div>
        </li>
      </ul>
    </div>



  </nav>
  <div class="copyright-container d-flex justify-content-between flex-wrap align-items-center">
    <p class="copyright-text">Copyright © Smart Studio SRL. All rights reserved</p>
    <button *ngIf="!logged" (click)="goToLogareDirector()" class="button green">Autentificare Director</button>
    <button *ngIf="logged && loggedType == 999" (click)="logOut()" class="button red">Iesire</button>
  </div>
  <div class="developed-by-container d-flex justify-content-center flex-wrap align-items-center">
    <span class="developed-text">Developed by <a href="mailto:deltasquadapps@gmail.com">Delta Squad SRL</a></span>
  </div>
</div>

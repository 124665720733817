import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {GradiniteService} from '../services/gradinite-service';

@Component({
  selector: 'app-log-dir-complete-grad-info',
  templateUrl: './log-dir-complete-grad-info.component.html',
  styleUrls: ['./log-dir-complete-grad-info.component.css']
})
export class LogDirCompleteGradInfoComponent implements OnInit {

  constructor(private router: Router, private gradiniteService: GradiniteService) {

    if(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK') == null) {
      this.router.navigateByUrl('/');
    }

  }

  ngOnInit() {
    this.getGradinitaInformatie();
  }

  dataLimita = '';
  numarLocuriDisponibile = 0;
  emailDeContact = '';
  numarCadreDidactice = 0;
  numarDiplome = 0;
  activitati = '';
  infrastructura = '';
  limbeDeInstruire = '';
  numarDeContact = '';
  adreseleDeDeservire = '';

  confirmare = false;

  getGradinitaInformatie() {
    this.gradiniteService.gradinita_informatie_completa(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK')).subscribe((data) => {
      if(data.status) {
        const gradinita = data.gradinita;

        const dateLimit = new Date();
        dateLimit.setTime(gradinita.termen_limita);

        let normalizedMonth = '';
        let normalizedDay = '';

        if(dateLimit.getMonth() + 1 < 10) {
          normalizedMonth += '0';
        }
        normalizedMonth += (dateLimit.getMonth() + 1);

        if(dateLimit.getDate() < 10) {
          normalizedDay += '0';
        }
        normalizedDay += dateLimit.getDate();

        this.dataLimita = dateLimit.getFullYear() + '-' + normalizedMonth + '-' + normalizedDay;

        this.numarLocuriDisponibile = gradinita.locuri_disponibile;
        this.emailDeContact = gradinita.email_contact;
        this.numarCadreDidactice = gradinita.numar_cadre_didactice;
        this.numarDiplome = gradinita.numar_diplome;

        this.activitati = '';
        for(let i = 0; i < gradinita.activitati.length; i++) {
          if(i != 0) {
            this.activitati += ',';
          }
          this.activitati += gradinita.activitati[i];
        }

        this.infrastructura = '';
        for(let i = 0; i < gradinita.infrastructura.length; i++) {
          if(i != 0) {
            this.infrastructura += ',';
          }
          this.infrastructura += gradinita.infrastructura[i];
        }

        this.limbeDeInstruire = '';
        for(let i = 0; i < gradinita.limba_de_instruire.length; i++) {
          if(i != 0) {
            this.limbeDeInstruire += ',';
          }
          this.limbeDeInstruire += gradinita.limba_de_instruire[i];
        }

        this.numarDeContact = gradinita.numar_de_contact;

        this.adreseleDeDeservire = '';

        for(let i = 0; i < gradinita.adrese.length; i++){
          if(i != 0) {
            this.adreseleDeDeservire += '\n';
          }
          this.adreseleDeDeservire += gradinita.adrese[i];
        }
      }
    }, error => {
      console.log(error);
    })
  }

  salvareaInformatiei() {
    const check = this.validateInputs();

    if(check) {

      const dataTime = new Date(this.dataLimita).getTime();

      let numar_cadre_didactice = this.numarCadreDidactice;

      if(numar_cadre_didactice == null) {
        numar_cadre_didactice = 0;
      }

      let numar_diplome = this.numarDiplome;

      if(numar_diplome == null) {
        numar_diplome = 0;
      }

      this.gradiniteService.editare_informatie(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK'), dataTime,
        this.numarLocuriDisponibile, numar_cadre_didactice, numar_diplome, this.emailDeContact, this.activitati, this.infrastructura,
        this.limbeDeInstruire, this.numarDeContact, this.adreseleDeDeservire).subscribe((data) => {
          if(data.status) {
            this.router.navigateByUrl('/lista-aplicari');
          }
          else{
            alert(data.message);
          }
      }, error => {
          alert('A intervenit o eroare');
          console.log(error);
      })
    }
  }

  validateInputs(): boolean {
    if(this.dataLimita.length == 0 || this.numarLocuriDisponibile == null || this.emailDeContact.length == 0 ||
      this.limbeDeInstruire.length == 0 || this.numarDeContact.length == 0) {
      alert('Data limita, numarul de locuri disponibile, limbele de instruire, emailul si numarul de contact sunt cimpuri obligatorii');

      return false;
    }
    else if(!this.validateEmail(this.emailDeContact)){
      alert('Introduceti o adresa de email valida');

      return false;
    }
    else if(!this.confirmare) {
      alert('Este necesar sa confirmati adresele de deservire');

      return false;
    }
    else {
      return true;
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}

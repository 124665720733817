import { Injectable} from '@angular/core';
import {BASE_URL} from '../constants';
import {HttpClient} from '@angular/common/http';
import {MotiveResponse} from '../models/main/motive-response';
import {DocumenteNecesareResponse} from '../models/main/documente-necesare-response';
import {CazPrioritateResponse} from '../models/main/caz-prioritate-response';
import {StatisticaResponse} from '../models/main/statistica-response';
import {FaqResponse} from '../models/main/faq-response';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  baseMainUrl = BASE_URL + '/';

  httpOptions = {};

  /** URLS **/

  motiveUrl = 'motive';
  documente_necesareUrl = 'documente_necesare';
  caz_prioritateUrl = 'caz_prioritate';
  statisticaUrl = 'statistica';
  faqUrl = 'faq';

  constructor(private http: HttpClient) {}

  getMotive() {
    const fullUrl = this.baseMainUrl + this.motiveUrl;

    return this.http.get<MotiveResponse>(fullUrl, this.httpOptions);
  }

  getDocumenteNecesare() {
    const fullUrl = this.baseMainUrl + this.documente_necesareUrl;

    return this.http.get<DocumenteNecesareResponse>(fullUrl, this.httpOptions);
  }

  getCazPrioritate() {
    const fullUrl = this.baseMainUrl + this.caz_prioritateUrl;

    return this.http.get<CazPrioritateResponse>(fullUrl, this.httpOptions);
  }

  getStatistica() {
    const fullUrl = this.baseMainUrl + this.statisticaUrl;

    return this.http.get<StatisticaResponse>(fullUrl, this.httpOptions);
  }

  getFaq() {
    const fullUrl = this.baseMainUrl + this.faqUrl;

    return this.http.get<FaqResponse>(fullUrl, this.httpOptions);
  }

}

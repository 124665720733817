<div class="customer-main-container d-flex flex-column w-100">
  <div class="view-tabs-back-container d-flex align-items-end justify-content-between flex-wrap">
    <div class="view-tabs-back-min-container" style="min-height: 3rem !important;">
      <ul class="tabs-cont d-flex align-items-end">
        <li [ngClass]="selectedStatut == -1 ? 'active' : ''" (click)="changeStatut(-1)">Toate</li>
        <li [ngClass]="selectedStatut == 3 ? 'active' : ''" (click)="changeStatut(3)">Acceptate</li>
        <li [ngClass]="selectedStatut == 2 ? 'active' : ''" (click)="changeStatut(2)">Inregistrat pentru aplicare</li>
        <li [ngClass]="selectedStatut == 0 ? 'active' : ''" (click)="changeStatut(0)">In procesare</li>
        <li [ngClass]="selectedStatut == 1 ? 'active' : ''" (click)="changeStatut(1)">Refuzate</li>
        <li [ngClass]="selectedStatut == 4 ? 'active' : ''" (click)="changeStatut(4)">Transferate</li>
      </ul>
    </div>
    <span (click)="goToModificaGradinita()" class="modifica-info-grad">Modifica info gradinita</span>
    <input [(ngModel)]="query" (ngModelChange)="getCererilDepuse()" type="text" class="text-left gen-input-white-bkg" placeholder="Cautare dupa nr. cererii sau IDNP ( ultimele 4 cifre)" style="max-width: 350px; width: 100%;">
  </div>



  <div class="show-grad-container-main d-flex flex-column w-100">

    <div *ngFor="let cerere of cerereList" class="grad-container w-100 mt-2 mb-3">
      <div class="upper-grad-min-container d-flex justify-content-between flex-wrap">
        <div class="grad-gen-info-container d-flex mb-1">
          <div class="gen-grad-info-container d-flex ml-3 flex-column">
            <span class="grad-sub-info-container">
              <span class="available">#{{ cerere.normalized_cerere_number }}</span>
              <span class="available ml-4">{{ cerere.data_crearii | date: 'HH:mm' }}</span>
              <span class="available ml-4">{{ cerere.data_crearii | date: 'dd/MM/yyyy' }}</span>
              <button *ngIf="!cerere.cerere_details_opened" class="open-close-info-button open-button ml-4" (click)="cerere.cerere_details_opened = true;"> Vezi detalii <img src="../../assets/mainp/down-arrow%201.png" class="open-close-arrow-img open "></button>
              <button *ngIf="cerere.cerere_details_opened" class="open-close-info-button close-button ml-4" (click)="cerere.cerere_details_opened = false;"> Ascunde <img src="../../assets/mainp/down-arrow%201.png" class="open-close-arrow-img close "></button>
              <span *ngIf="(cerere.statut != 1 && cerere.statut != 3 && cerere.statut != 2) && cerere.solicitare_acte" class="acte-aditionale ml-4">Au fost solicitate acte aditionale</span>
              <span *ngIf="(cerere.statut != 1 && cerere.statut != 3) && cerere.transferat" class="cerere-transferata ml-4">Cererea a fost transferata</span>
            </span>
            <span class="grad-name-container mt-3">{{ cerere.copil_prenume }}</span>
          </div>
        </div>
        <div class="grad-options-container d-flex flex-column justify-content-between align-items-center">
          <span *ngIf="cerere.statut == 3" id="accepted-status" class="cerere-status align-items-center"><p class="status-text">Acceptata</p><img src="../../assets/mainp/check-mark.png" class="status-img"></span>
          <span *ngIf="cerere.statut == 0" id="processing-status" class="cerere-status align-items-center"><p class="status-text">Se proceseaza</p><img src="../../assets/mainp/processing.png" class="status-img"></span>
          <span *ngIf="cerere.statut == 1" id="declined-status" class="cerere-status align-items-center"><p class="status-text">Refuzata</p><img src="../../assets/mainp/cancel.png" class="status-img"></span>
          <span *ngIf="cerere.statut == 2" id="apply-documents-status" class="cerere-status align-items-center"><p class="status-text">Spre aplicare</p><img src="../../assets/mainp/documents.png" class="status-img"></span>
        </div>
      </div>
      <div *ngIf="cerere.cerere_details_opened" class="w-100 vezi-info-cereri-depuse flex-column">
        <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column">
          <span class="address-header mb-4">Limba de instruire</span>
          <div class="inputs-label-container d-flex flex-column w-100">
            <p class="show-info-cereri-depuse mt-4" style="margin-left: 1rem;">
              {{ cerere.limba_instruire }}
            </p>
          </div>
        </div>
        <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column info-parinte-copil-container">
          <span class="address-header">Informatie Parinte</span>

          <div class="d-flex flex-wrap  w-100 mt-4">
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">IDNP</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.parinte_idnp }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Nume/Prenume</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.parinte_nume }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Nr. Telefon</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.parinte_telefon }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Email</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.parinte_email }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Localitate</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.parinte_localitate }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Adresa</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.parinte_adresa }}
              </p>
            </div>
          </div>

        </div>
        <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column info-parinte-copil-container">
          <span class="address-header">Informatie Copil</span>

          <div class="d-flex flex-wrap  w-100 mt-4">
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">IDNP</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.copil_idnp }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Nume/Prenume</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.copil_prenume }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Anul nasterii</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.copil_anul_nasterii }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Sexul</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.copil_sexul }}
              </p>
            </div>
          </div>

        </div>
        <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column caz-prior">
          <span class="address-header mb-4">Caz cu prioritate</span>

          <ul class="caz-prior-list">
            <li *ngFor="let caz of cerere.caz_prioritate"><input type="checkbox" checked>{{ caz }}</li>
          </ul>
        </div>
        <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column caz-prior">
          <span class="address-header mb-4">Documentele incarcate</span>

          <ul class="caz-prior-list">
            <li *ngFor="let document of cerere.documente_incarcate"><input type="checkbox" checked>{{ document.document_name }}</li>
          </ul>
          <button (click)="descarcaToateDocumentele(cerere)" class="button blue" style="position: relative;padding-left: 4rem;display: flex;align-items: center;"><img style="position: absolute; left: 10px;" src="../../assets/mainp/upload%20(1)%201.png">Descarca toate</button>
        </div>
        <div *ngIf="cerere.cerere_transfer_deschis" id="transfera-manipulare" class="manipulate-gen-container flex-column align-items-start w-100 mt-5 buttons-width">
          <div class="inputs-label-container w-100 d-flex flex-column ">
            <label class="inputs-label">Comentariu</label>
            <textarea [(ngModel)]="cerere.cerere_comentariu" class="textarea-input gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1"></textarea>
          </div>
          <div class="inputs-label-container d-flex flex-column w-100 mt-3">
            <label class="inputs-label">Motiv</label>
            <select [(ngModel)]="cerere.cerere_motiv" class="custom-select" style="margin-top: 0px !important;">
              <option *ngFor="let motiv of motiveTransfer; let position = index;" [value]="position">{{ motiv }}</option>
            </select>
          </div>
          <button (click)="closeTransfer(cerere)" class="button red mt-4">Anulare</button>
          <button (click)="transferaCererea(cerere)" class="button green mt-4">Transfera</button>
        </div>
        <div *ngIf="cerere.cerere_solicita_acte_deschis" id="solicita-acte" class="manipulate-gen-container flex-column align-items-start w-100 mt-5 buttons-width">
          <div class="inputs-label-container w-100 d-flex flex-column ">
            <label class="inputs-label">Comentariu</label>
            <textarea [(ngModel)]="cerere.cerere_comentariu" class="textarea-input gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1"></textarea>
          </div>
          <button (click)="closeSolicitaActe(cerere)" class="button red mt-4">Anulare</button>
          <button (click)="solicitaActe(cerere)" class="button green mt-4">Solicita acte</button>
        </div>
        <div *ngIf="cerere.cerere_inregistrare_pentru_aplicare_deschis" id="inreg-aplicare" class="manipulate-gen-container flex-column align-items-start w-100 mt-5 buttons-width">
          <div class="inputs-label-container w-100 d-flex flex-column ">
            <label class="inputs-label">Comentariu</label>
            <textarea [(ngModel)]="cerere.cerere_comentariu" class="textarea-input gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1"></textarea>
          </div>
          <button (click)="closeInregistreazaPentruAplicare(cerere)" class="button red mt-4">Anulare</button>
          <button (click)="inregistreazaPentruAplicare(cerere)" class="button green mt-4">Inregistreaza pentru aplicare</button>
        </div>
        <div *ngIf="cerere.cerere_respinge_deschis" id="respinge" class="manipulate-gen-container flex-column align-items-start w-100 mt-5 buttons-width">
          <div class="inputs-label-container w-100 d-flex flex-column ">
            <label class="inputs-label">Comentariu</label>
            <textarea [(ngModel)]="cerere.cerere_comentariu" class="textarea-input gen-input-white-bkg welcome-input w-100 mt-1 text-left pl-1 pr-1"></textarea>
          </div>
          <div class="inputs-label-container d-flex flex-column w-100 mt-3">
            <label class="inputs-label">Motiv</label>
            <select [(ngModel)]="cerere.cerere_motiv" class="custom-select" style="margin-top: 0px !important;">
              <option *ngFor="let motiv of motiveRespingere; let position = index" [value]="position">{{ motiv }}</option>
            </select>
          </div>
          <button class="button red mt-4" (click)="closeRespingereCerere(cerere)">Anulare</button>
          <button class="button green mt-4" (click)="respingeCererea(cerere)">Respinge</button>
        </div>
        <div *ngIf="!cerere.cerere_respinge_deschis && !cerere.cerere_solicita_acte_deschis && !cerere.cerere_inregistrare_pentru_aplicare_deschis && !cerere.cerere_transfer_deschis" class="d-flex  flex-wrap justify-content-center buttons-width">
          <button *ngIf="selectedStatut != 4 && cerere.statut != 1 && cerere.statut != 3" class="button green mt-5" (click)="inmatriculeaza(cerere)">Inmatriculeaza</button>
          <button *ngIf="selectedStatut != 4 && cerere.statut != 1 && cerere.statut != 3" class="button blue ml-4 mt-5" (click)="cerere.cerere_inregistrare_pentru_aplicare_deschis = true;">Inregistreaza pentru aplicare</button>
          <button *ngIf="selectedStatut != 4 && cerere.statut != 1 && cerere.statut != 3 && cerere.statut != 2 && !cerere.solicitare_acte" class="button yellow ml-4 mt-5" (click)="cerere.cerere_solicita_acte_deschis = true;"> Solicita acte</button>
          <button *ngIf="selectedStatut != 4 && cerere.statut != 1 && cerere.statut != 3 && cerere.statut != 2" class="button orange ml-4 mt-5" (click)="cerere.cerere_transfer_deschis = true;"> Transfera</button>
          <button *ngIf="selectedStatut != 4 && cerere.statut != 1 && cerere.statut != 3" class="button red ml-4 mt-5" (click)="cerere.cerere_respinge_deschis = true;">Respinge</button>
        </div>
      </div>

    </div>





  </div>

</div>

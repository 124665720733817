import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {GradiniteService} from '../services/gradinite-service';
import {CereriService} from '../services/cereri-service';
import {MainService} from '../services/main-service';
import {ParinteCommunication} from '../services/parinte-communication';
import {GradinitaFullModel} from '../models/gradinita/gradinita-full-model';
import {CazPrioritateModel} from '../models/main/submodel/caz-prioritate-model';
import {DocumentModificaCerereModel} from '../models/cereri/submodel/document-modifica-cerere-model';

@Component({
  selector: 'app-modifica-cererea',
  templateUrl: './modifica-cererea.component.html',
  styleUrls: ['./modifica-cererea.component.css']
})
export class ModificaCerereaComponent implements OnInit {

  @ViewChild('file', {static: true}) file: ElementRef;

  constructor(private router: Router, private gradiniteService: GradiniteService, private cereriService: CereriService,
              private mainService: MainService, private parinteCommunication: ParinteCommunication) {
    if(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza') == null) {
      this.router.navigateByUrl('/');
    }
    if(this.parinteCommunication.gradinita_id_selected == 0) {
      this.router.navigateByUrl('/parinte-main');
    }
    if(this.parinteCommunication.cerere_id_selected == 0) {
      this.router.navigateByUrl('/parinte-main');
    }
  }

  ngOnInit() {
    this.currentTime = new Date().getTime();

    this.getCazuriPrioritate();
    this.getDocumenteNecesare();

    this.getGradinitaDetails();
  }

  currentTime: number = 0;

  cerereProcessing: boolean = false;

  gradinitaDetails: GradinitaFullModel = new GradinitaFullModel();

  cazuriNecesare: CazPrioritateModel[] = [];

  documenteNecesare: string[] = [];

  /** CERERE FIELDS **/
  limbaSelectata: number = 0;
  copilSexul: number = 0;

  parinteIdnp: string = '';
  parinteNume: string = '';
  parinteNrTelefon: string = '';
  parinteEmail: string = '';
  parinteLocalitate: string = '';
  parinteAdresa: string = '';

  copilIdnp: string = '';
  copilNume: string = '';
  copilAnulNasterii: string = '';

  documenteleIncarcate: DocumentModificaCerereModel[] = [];

  /**  ###########  **/

  getListaDeDocumente() {
    this.cereriService.documentele_atasate(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'),
      String(this.parinteCommunication.cerere_id_selected)).subscribe((data) => {
        if(data.status) {
          this.documenteleIncarcate = [];

          for(let i = 0; i < data.documente.length; i++) {
            const tempObject = new DocumentModificaCerereModel();
            tempObject.id = data.documente[i].id;
            tempObject.document_name = data.documente[i].document_name;
            tempObject.file = undefined;
            tempObject.remove = false;
            tempObject.add = false;
            this.documenteleIncarcate.push(tempObject);
          }
        }
    }, error => {
        console.log(error);
    })
  }

  getCerereDetalii() {
    this.cereriService.cerere_detalii(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'),
      String(this.parinteCommunication.cerere_id_selected)).subscribe((data) => {
        if(data.status) {
          const cerere = data.cerere;
          for(let i = 0; i < this.gradinitaDetails.limba_de_instruire.length; i++) {
            if(this.gradinitaDetails.limba_de_instruire[i] == cerere.limba_instruire) {
              this.limbaSelectata = i;
            }
          }

          for(let i = 0; i < cerere.caz_prioritate.length; i++) {
            for(let j = 0; j < this.cazuriNecesare.length; j++) {
              if(cerere.caz_prioritate[i] == this.cazuriNecesare[j].caz) {
                this.cazuriNecesare[j].selectat = true;
              }
            }
          }

          if(cerere.copil_sexul == 'Masculin') {
            this.copilSexul = 0;
          }
          else{
            this.copilSexul = 1;
          }

          this.parinteIdnp = cerere.parinte_idnp;
          this.parinteNume = cerere.parinte_nume;
          this.parinteNrTelefon = cerere.parinte_telefon;
          this.parinteEmail = cerere.parinte_email;
          this.parinteLocalitate = cerere.parinte_localitate;
          this.parinteAdresa = cerere.parinte_adresa;

          this.copilIdnp = cerere.copil_idnp;
          this.copilNume = cerere.copil_prenume;
          this.copilAnulNasterii = cerere.copil_anul_nasterii;

          this.getListaDeDocumente();
        }
    }, error => {
        console.log(error);
    })
  }

  getGradinitaDetails() {
    this.gradiniteService.informatie_deplina(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'),
      String(this.parinteCommunication.gradinita_id_selected)).subscribe((data) => {
      if(data.status) {
        this.gradinitaDetails = data.gradinita;

        this.getCerereDetalii();
      }
    }, error => {
      console.log(error);
    })
  }

  getCazuriPrioritate() {
    this.mainService.getCazPrioritate().subscribe((data) => {
      if(data.status) {
        const cazuri = data.cazuri;
        this.cazuriNecesare = [];

        for(let i = 0; i < cazuri.length; i++) {
          const caz = new CazPrioritateModel();
          caz.caz = cazuri[i];
          this.cazuriNecesare.push(caz);
        }
      }
    }, error => {
      console.log(error);
    })
  }

  getDocumenteNecesare() {
    this.mainService.getDocumenteNecesare().subscribe((data) => {
      if(data.status) {
        this.documenteNecesare = data.documente;
      }
    }, error => {
      console.log(error);
    })
  }

  inapoiLaGradinite(){
    this.router.navigateByUrl('/parinte-main');
  }

  incarcaDocument(event) {
    let file = <File> event.target.files[0];

    const tempObject = new DocumentModificaCerereModel();
    tempObject.document_name = file.name;
    tempObject.add = true;
    tempObject.file = file;

    this.documenteleIncarcate.push(tempObject);

    this.file.nativeElement.value = '';
  }

  stergeDocumentul(pozitia: number) {
    this.documenteleIncarcate[pozitia].remove = true;
  }

  validateInputs() : boolean {

    if(this.parinteIdnp.length == 0 || this.parinteNume.length == 0 || this.parinteNrTelefon.length == 0 || this.parinteEmail.length == 0 ||
      this.parinteLocalitate.length == 0 || this.parinteAdresa.length == 0 || this.copilIdnp.length == 0 || this.copilNume.length == 0 ||
      this. copilAnulNasterii.length == 0) {
      alert('Toate cimpurile sunt obligatorii');

      return false;
    }
    else if (!this.validateEmail(this.parinteEmail)) {
      alert('Introduceti o adresa de email valida');

      return false;
    }
    else{
      return true;
    }
  }

  processDocuments(position: number) {
    if(this.documenteleIncarcate[position].add) {
      this.cereriService.upload_documente(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'),
        this.documenteleIncarcate[position].file, String(this.parinteCommunication.cerere_id_selected)).subscribe((data) => {
          if(data.status) {
            if(position == this.documenteleIncarcate.length -1) {
              this.cerereProcessing = false;
              this.router.navigateByUrl('/cereri-depuse');
            }
            else{
              this.processDocuments(position + 1);
            }
          }
      }, error => {
          console.log(error);
      })
    }
    else if(this.documenteleIncarcate[position].remove) {
      this.cereriService.sterge_documentul(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'),
        String(this.parinteCommunication.cerere_id_selected), String(this.documenteleIncarcate[position].id)).subscribe((data) => {
          if(data.status) {
            if(position == this.documenteleIncarcate.length -1) {
              this.cerereProcessing = false;
              this.router.navigateByUrl('/cereri-depuse');
            }
            else{
              this.processDocuments(position + 1);
            }
          }
      }, error => {
          console.log(error);
      })
    }
    else{
      if(position == this.documenteleIncarcate.length -1) {
        this.cerereProcessing = false;
        this.router.navigateByUrl('/cereri-depuse');
      }
      else{
        this.processDocuments(position + 1);
      }
    }
  }

  modificareCerere() {
    const check = this.validateInputs();

    if(check) {

      if(!this.cerereProcessing) {
        this.cerereProcessing = true;

        let cazuriString = '';

        for (let i = 0; i < this.cazuriNecesare.length; i++) {
          if (this.cazuriNecesare[i].selectat) {
            if (cazuriString.length != 0) {
              cazuriString += ',';
            }
            cazuriString = cazuriString + this.cazuriNecesare[i].caz;
          }
        }

        let copilSex = 'Masculin';

        if (this.copilSexul == 1) {
          copilSex = 'Feminin';
        }

        this.cereriService.editare_cererea(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'), this.parinteCommunication.cerere_id_selected,
          this.gradinitaDetails.limba_de_instruire[this.limbaSelectata], this.parinteIdnp, this.parinteNume,
          this.parinteNrTelefon, this.parinteEmail, this.parinteLocalitate, this.parinteAdresa, this.copilIdnp, this.copilNume,
          this.copilAnulNasterii, copilSex, cazuriString).subscribe((data) => {
          if (data.status) {
            this.processDocuments(0);
          }
        }, error => {
          this.cerereProcessing = false;
          console.log(error);
        });
      }
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}

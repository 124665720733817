import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MainCommunication} from '../services/main-communication';

@Component({
  selector: 'app-upper-nav',
  templateUrl: './upper-nav.component.html',
  styleUrls: ['./upper-nav.component.css']
})
export class UpperNavComponent implements OnInit {

  constructor(public router: Router, private mainCommunication: MainCommunication) {
    this.mainCommunication.loggedChanged$.subscribe((data) => {
      if(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza') != null && localStorage.getItem('logged_in') == 'customer') {
        this.logged = true;
        this.loggedType = 0;
      }
      else if(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK') != null && localStorage.getItem('logged_in') == 'director') {
        this.logged = true;
        this.loggedType = 999;
      }
      else {
        this.logged = false;
        this.loggedType = -1;
      }
    })
  }

  ngOnInit() {
    if(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza') != null && localStorage.getItem('logged_in') == 'customer') {
      this.logged = true;
      this.loggedType = 0;
    }
    else if(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK') != null && localStorage.getItem('logged_in') == 'director') {
      this.logged = true;
      this.loggedType = 999;
    }
    else {
      this.logged = false;
      this.loggedType = -1;
    }
  }

  logged = false;
  loggedType = -1;

  goToAcasa() {
    this.router.navigateByUrl('/');
  }

  goToProfil() {
    if(this.loggedType == 0) {
      this.router.navigateByUrl('/parinte-main')
    }
    else{
      this.router.navigateByUrl('/lista-aplicari');
    }
  }

  goToDespreNoi() {
    this.mainCommunication.despreNoi();
  }

  goToIntrebariFrecvente() {
    //TODO INTREBARI FRECVENTE
  }

  goToInstructiuniDeAplicare() {
    this.mainCommunication.instructiuniDeAplicare();
  }

  goToRegulament() {
    //TODO GO TO REGULAMENT
  }

  goToAutentificare() {
    this.router.navigateByUrl('/logare-parinte');
  }

  goToInregistrare() {
    this.router.navigateByUrl('/inregistrare-parinte');
  }

  logOut() {
    localStorage.clear();
    this.mainCommunication.loggedChanged();
    this.router.navigateByUrl('/');
  }

}

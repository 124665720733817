export class GradinitaFullModel {
  id: number;
  nume: string;
  sector_id: number;
  sector_name: string;
  adresa_gradinita: string;
  locuri_disponibile: number;
  email_contact: string;
  numar_cadre_didactice: number;
  numar_diplome: number;
  numar_de_contact: string;
  termen_limita: number;
  activitati: string[];
  infrastructura: string[];
  limba_de_instruire: string[];
  adrese: string[];
  cerere_depuse = false;
  cerere_id = 0;
}

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ParinteCommunication} from '../services/parinte-communication';
import {CereriService} from '../services/cereri-service';
import {CerereModel} from '../models/cereri/submodel/cerere-model';

@Component({
  selector: 'app-cereri-depuse',
  templateUrl: './cereri-depuse.component.html',
  styleUrls: ['./cereri-depuse.component.css']
})
export class CereriDepuseComponent implements OnInit {

  constructor(private router: Router, private cereriService: CereriService, private parinteCommunication: ParinteCommunication) {
    if(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza') == null) {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit(): void {
    this.getCererileDepuse();
  }

  selectedStatut: number = -1;

  cereriList: CerereModel[] = [];

  changeStatut(statut: number) {
    this.selectedStatut = statut;
    this.getCererileDepuse();
  }

  getCererileDepuse() {
    this.cereriService.cererile_mele(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'), String(this.selectedStatut)).subscribe((data) => {
      if(data.status) {

        const cereri = data.cereri;

        for(let i = 0; i < cereri.length; i++) {
          cereri[i].normalized_cerere_number = String(cereri[i].id);

          while(cereri[i].normalized_cerere_number.length < 4) {
            cereri[i].normalized_cerere_number = '0' + cereri[i].normalized_cerere_number;
          }
        }

        this.cereriList = cereri;
      }
    }, error => {
      console.log(error);
    })
  }

  cerereNoua() {
    this.router.navigateByUrl('/parinte-main');
  }

  modificaDatele(cerere: CerereModel) {
    this.parinteCommunication.setCerereIdSelectd(cerere.id);
    this.router.navigateByUrl('/modifica-cerere');
  }

  documenteleAtasate(cerere: CerereModel) {
    this.parinteCommunication.setCerereIdSelectd(cerere.id);
    this.parinteCommunication.setGradinitaNume(cerere.nume_gradinita);
    this.router.navigateByUrl('/documente-atasate');
  }

  retrageCererea(cerere: CerereModel) {
    this.parinteCommunication.setCerereIdSelectd(cerere.id);
    this.router.navigateByUrl('/retrage-cererea');
  }
}

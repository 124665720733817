<div class="customer-main-container d-flex flex-column w-100">
  <button (click)="anuleazaTransferul()" class="button red mt-4 mb-2" style="width: 100%; max-width: 250px; margin: 0 3rem 0 3rem;"> Anuleaza</button>
  <div class="view-tabs-back-container d-flex align-items-end justify-content-between flex-wrap">
    <div class="view-tabs-back-min-container" style="min-height: 3rem !important;">
      <ul class="tabs-cont d-flex align-items-end">
        <li [ngClass]="sectorulAles == 0 ? 'active' : ''" (click)="schimbaSectorul(0)">Toate</li>
        <li *ngFor="let sector of sectoare" [ngClass]="sectorulAles == sector.id ? 'active' : ''" (click)="schimbaSectorul(sector.id)">{{ sector.sector_nume }}</li>
      </ul>
    </div>
    <input [(ngModel)]="query" (ngModelChange)="getGradiniteSectoare()" type="text" class="text-left gen-input-white-bkg" placeholder="Cautare dupa adresa de locuinta" style="max-width: 350px; width: 100%;">
  </div>



  <div class="show-grad-container-main d-flex flex-column w-100">

    <div *ngFor="let gradinita of gradinitaList" class="grad-container w-100 mt-2 mb-3">
      <div class="upper-grad-min-container d-flex justify-content-between flex-wrap">
        <div class="grad-gen-info-container d-flex mb-1">
          <img src="../../assets/mainp/school%201.png" class="gen-left-img">
          <div class="gen-grad-info-container d-flex ml-3 flex-column">
            <span class="grad-name-container mb-3">{{ gradinita.nume }}</span>
            <span *ngIf="gradinita.termen_limita >= currentTime" class="grad-sub-info-container">Termen limita de primire a cererilor : <span [ngClass]="gradinita.termen_limita >= currentTime && gradinita.locuri_disponibile > 0  ? 'available' : 'unavailable'">{{ gradinita.termen_limita | date: 'dd/MM/yyyy' }}</span></span>
            <span *ngIf="gradinita.termen_limita < currentTime" class="grad-sub-info-container">Termen limita de primire a cererilor : <span [ngClass]="gradinita.termen_limita >= currentTime && gradinita.locuri_disponibile > 0  ? 'available' : 'unavailable'">Trecut</span></span>
            <span class="grad-sub-info-container">Locuri libere : <span [ngClass]="gradinita.termen_limita >= currentTime && gradinita.locuri_disponibile > 0 ? 'available' : 'unavailable'">{{ gradinita.locuri_disponibile }}</span></span>
          </div>
        </div>
        <div class="grad-options-container d-flex flex-column justify-content-between align-items-start">
          <button *ngIf="!gradinita.cerere_depuse" [disabled]="gradinita.termen_limita < currentTime || gradinita.locuri_disponibile <= 0 " [ngClass]="gradinita.termen_limita < currentTime || gradinita.locuri_disponibile <= 0 ? 'gray' : 'green'" class="button" (click)="transferaCererea(gradinita)">Transfera</button>
          <button *ngIf="gradinita.cerere_depuse" class="button orange">Cerere depusa</button>
          <button *ngIf="!gradinita.detalii_deschise" class="open-close-info-button open-button" (click)="gradinita.detalii_deschise = true;"> Vezi detalii <img src="../../assets/mainp/down-arrow%201.png" class="open-close-arrow-img open"></button>
          <button *ngIf="gradinita.detalii_deschise" class="open-close-info-button close-button" (click)="gradinita.detalii_deschise = false;"> Vezi detalii <img src="../../assets/mainp/down-arrow%201.png" class="open-close-arrow-img close"></button>
        </div>
      </div>

      <div *ngIf="gradinita.detalii_deschise" class="down-grad-min-container mt-2 pt-2 d-flex align-items-end justify-content-between">
        <div class="address-container d-flex flex-column">
          <span class="address-header">Adresele de deservire</span>
          <ul class="address-list">
            <li *ngFor="let adresa of gradinita.adrese_deservire" class="address-item"><div class="address-bulina"></div>{{ adresa }}</li>
          </ul>
        </div>
        <div class="more-info-container d-flex flex-column w-100 mt-5">
          <div class="more-info-item d-flex align-items-end underlined">
            <img class="more-info-img" src="../../assets/mainp/city%201.png">
            <span class="more-info-text ml-3"><span class="available"></span>{{ gradinita.sector_nume }}</span>
          </div>
          <div class="more-info-item d-flex align-items-end underlined">
            <img class="more-info-img" src="../../assets/mainp/maps-and-flags.png">
            <span class="more-info-text ml-3"><span class="available"></span>{{ gradinita.adresa_gradinita }}</span>
          </div>
          <div class="more-info-item d-flex align-items-end underlined">
            <img class="more-info-img" src="../../assets/mainp/students%201.png">
            <span class="more-info-text ml-3"><span [ngClass]="gradinita.termen_limita >= currentTime && gradinita.locuri_disponibile > 0 ? 'available' : 'unavailable'">{{ gradinita.locuri_disponibile == 0 ? 'nu sunt' : gradinita.locuri_disponibile }} </span>locuri libere</span>
          </div>
<!--          <button class="button w-100 blue mt-5" (click)="toataInformatiaGradinita(gradinita)">Toata informatia</button>-->
        </div>
      </div>


    </div>


  </div>

</div>

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DirectorCommunication {

  cerere_id: number = 0;

  cerere_respingere_comentariu: string = '';
  cerere_respingere_motiv: number = -1;

  gradinita_id: number = 0;
  to_gradinita_id: number = 0;

  client_id: number = 0;

  setCerereRespins(cerere_id: number, cerere_respingere_comentariu: string, cerere_respingere_motiv: number) {
    this.cerere_id = cerere_id;
    this.cerere_respingere_comentariu = cerere_respingere_comentariu;
    this.cerere_respingere_motiv = cerere_respingere_motiv;
  }

  clearData(){
    this.cerere_id = 0;
    this.cerere_respingere_comentariu = '';
    this.cerere_respingere_motiv = -1;
  }

}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CereriService} from '../services/cereri-service';
import {ParinteCommunication} from '../services/parinte-communication';
import {DocumentModel} from '../models/cereri/submodel/document-model';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-documente-atasate',
  templateUrl: './documente-atasate.component.html',
  styleUrls: ['./documente-atasate.component.css']
})
export class DocumenteAtasateComponent implements OnInit {

  @ViewChild('file', {static: true}) file: ElementRef;

  constructor(private router: Router, private cereriService: CereriService, private parinteCommunication: ParinteCommunication) {

    if(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza') == null) {
      this.router.navigateByUrl('/');
    }

    if(this.parinteCommunication.cerere_id_selected == 0) {
      this.router.navigateByUrl('/cereri-depuse')
    }

    this.gradinitaNume = this.parinteCommunication.gradinita_nume;
  }

  ngOnInit(): void {
    this.getDocumenteleAtasate();
  }

  gradinitaNume = '';

  documenteList: DocumentModel[] = [];

  inapoiCereriDepuse() {
    this.router.navigateByUrl('/cereri-depuse');
  }

  getDocumenteleAtasate() {
    this.cereriService.documentele_atasate(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'), String(this.parinteCommunication.cerere_id_selected)).subscribe((data) => {
      if(data.status){
        this.documenteList = data.documente;
      }
    }, error => {
      console.log(error);
    })
  }

  descarcaDocument(document: DocumentModel) {
    this.cereriService.download_documentul(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'), String(document.id)).subscribe((data) => {

      saveAs(data, document.document_name);

    }, error => {
      console.log(error);
    })
  }

  incarcaDocument(event) {
    let file = <File> event.target.files[0];

    this.cereriService.upload_documente(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'), file,
      String(this.parinteCommunication.cerere_id_selected)).subscribe((data) => {
        this.file.nativeElement.value = "";
        if(data.status) {
          this.getDocumenteleAtasate();
        }
        else{
          alert(data.message);
        }
    }, error => {
      this.file.nativeElement.value = "";
        console.log(error);
    });
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cerere-depusa-error',
  templateUrl: './cerere-depusa-error.component.html',
  styleUrls: ['./cerere-depusa-error.component.css']
})
export class CerereDepusaErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

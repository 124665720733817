<div class="customer-main-container d-flex flex-column w-100">
  <div class="view-tabs-back-container d-flex align-items-end justify-content-between flex-wrap">
    <div class="view-tabs-back-min-container" style="min-height: 1.5rem !important;">
      <ul class="back-cont d-flex align-items-end">
        <li (click)="inapoiCereriDepuse()"><img class="back-arrow-img" src="../../assets/mainp/back%201.png"> Inapoi la cereri depuse</li>

      </ul>
    </div>
    <span class="doc-atas-head">Documente atasate</span>
    <div class="grad-gen-info-container d-flex align-items-end mt-2">
      <img src="../../assets/mainp/school%201.png" class="gen-left-img">
      <div class="gen-grad-info-container d-flex ml-3 flex-column">
        <span class="grad-name-container">{{ gradinitaNume }}</span>
      </div>
    </div>
  </div>


  <div class="d-flex w-100 justify-content-end mt-5" style="max-width: calc(100% - 60px); margin: 0px auto;">
    <input (change)="incarcaDocument($event)" class="d-none" type="file" #file>
    <button (click)="file.click()" class="button blue" style="position: relative;padding-left: 4rem;display: flex;align-items: center;"><img style="position: absolute; left: 10px;" src="../../assets/mainp/upload%20(1)%201.png">Incarca document</button>
  </div>
  <div class="show-grad-container-main d-flex flex-column w-100">

    <div *ngFor="let document of documenteList" class="grad-container w-100 mt-2 mb-3">
      <div class="upper-grad-min-container d-flex justify-content-between align-items-center flex-wrap">
        <div class="grad-gen-info-container d-flex">
          <span class="document-name">{{ document.document_name }}</span>
        </div>
        <div class="grad-options-container d-flex flex-column justify-content-between align-items-center">
          <button class="button blue" style="position: relative;padding-left: 4rem;display: flex;align-items: center;" (click)="descarcaDocument(document)"><img style="position: absolute; left: 10px; transform: rotateX(180deg);" src="../../assets/mainp/upload%20(1)%201.png">Descarca document</button>
        </div>
      </div>
    </div>





  </div>

</div>

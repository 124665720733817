import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthorizationService} from '../services/authorization-service';

@Component({
  selector: 'app-customer-register',
  templateUrl: './customer-register.component.html',
  styleUrls: ['./customer-register.component.css']
})
export class CustomerRegisterComponent implements OnInit {

  constructor(private router: Router, private autorizareService: AuthorizationService) { }

  ngOnInit() {
  }

  email = '';

  inregistrare() {
    if(this.email.length == 0) {
      alert('Cimpul de email este obligatoriu');
    }
    else if(!this.validateEmail(this.email)) {
      alert('Introduceti o adresa de email valida');
    }
    else {
      this.autorizareService.inregistrare_client(this.email).subscribe((data) => {
        if(data.status) {
          alert('Veti primi un cod de autentificare pe email-ul provizionat');
        }
        else{
          alert(data.message);
        }
      }, error => {
        alert('A intervenit o eroare la inregistrare');
      })
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}

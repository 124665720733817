import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthorizationService} from '../services/authorization-service';
import {MainCommunication} from '../services/main-communication';

@Component({
  selector: 'app-log-dir-second',
  templateUrl: './log-dir-second.component.html',
  styleUrls: ['./log-dir-second.component.css']
})
export class LogDirSecondComponent implements OnInit {

  constructor(private router: Router, private autorizareService: AuthorizationService, private mainCommunication: MainCommunication) {

    if(this.mainCommunication.token.length == 0) {
      this.router.navigateByUrl('/activare-acc-director');
    }

  }

  ngOnInit() {
  }

  email: string;
  password: string;
  nume_director: string;


  anulare() {
    this.router.navigateByUrl('/activare-acc-director');
  }

  pasulUrmator() {
    if(this.email.length == 0 || this.password.length == 0 || this.nume_director.length == 0) {
      alert('Toate cimpurile sunt obligatorii');
    }
    else if(!this.validateEmail(this.email)) {
      alert('Introduceti o adresa de email valida');
    }
    else if(this.password.length < 6) {
      alert('Lungimea parolei trebuie sa fie nu mai mica de 6 caractere');
    }
    else {
      this.autorizareService.inregistrare_cont_director(this.email, this.password, this.nume_director, this.mainCommunication.token).subscribe((data) => {
        if(data.status) {
          this.mainCommunication.token = '';
          this.router.navigateByUrl('/logare-director-success');
        }
        else{
          alert(data.message);
        }
      }, error => {
        alert('A intervenit o eroare');
      })
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthorizationService} from '../services/authorization-service';
import {GradiniteService} from '../services/gradinite-service';

@Component({
  selector: 'app-log-dir-first',
  templateUrl: './log-dir-first.component.html',
  styleUrls: ['./log-dir-first.component.css']
})
export class LogDirFirstComponent implements OnInit {

  constructor(private router: Router, private autorizareService: AuthorizationService, private gradiniteService: GradiniteService) { }

  ngOnInit() {
  }

  goToActivare() {
    this.router.navigateByUrl('activare-acc-director');
  }

  email: string;
  password: string;
  lastThree: string;

  autentificare() {
    if(this.email.length == 0 || this.password.length == 0 || this.lastThree.length == 0) {
      alert('Toate cimpurile sunt obligatorii');
    }
    else if(!this.validateEmail(this.email)){
      alert('Introduceti o adresa de email valida');
    }
    else {
      this.autorizareService.logare_director(this.email, this.password, this.lastThree).subscribe((data) => {
        if(data.status) {
          localStorage.setItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK', data.token);
          localStorage.setItem('logged_in', 'director');

          this.gradiniteService.informatie_completata(data.token).subscribe((data) => {
            if(data.status) {
              if(data.completed) {
                this.router.navigateByUrl('/lista-aplicari');
              }
              else{
                this.router.navigateByUrl('/logare-director-completare-info-gradinita');
              }
            }
            else{
              alert('A intervenit o eroare')
            }
          }, error => {
            console.log(error);
          })
        }
        else {
          alert('Datele de autentificare sunt gresite');
        }
      }, error => {
        alert('Datele de autentificare sunt gresite');
      })
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

}

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {GradiniteService} from '../services/gradinite-service';
import {CereriService} from '../services/cereri-service';
import {CerereModel} from '../models/cereri/submodel/cerere-model';
import { saveAs } from 'file-saver';
import {DocumentModel} from '../models/cereri/submodel/document-model';
import {MainCommunication} from '../services/main-communication';
import {MainService} from '../services/main-service';
import {DirectorCommunication} from '../services/director-communication';

@Component({
  selector: 'app-dir-applications-list',
  templateUrl: './dir-applications-list.component.html',
  styleUrls: ['./dir-applications-list.component.css']
})
export class DirApplicationsListComponent implements OnInit {

  constructor(private router: Router, private gradiniteService: GradiniteService, private cereriService: CereriService,
              private mainCommunication: MainCommunication, private mainService: MainService,
              private directorCommunication: DirectorCommunication) {

    if(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK') == null) {
      this.router.navigateByUrl('/');
    }
    else {
      this.gradiniteService.informatie_completata(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK')).subscribe((data) => {
        if (data.status) {
          if (!data.completed) {
            this.router.navigateByUrl('/logare-director-completare-info-gradinita');
          }
        }
      }, error => {
        console.log(error);
      })
    }

    this.mainCommunication.changedYear$.subscribe((data) => {
      this.selectedYear = this.mainCommunication.selectedYear;
      this.getCererilDepuse();
    })
  }

  ngOnInit(): void {
    this.selectedYear = new Date().getFullYear();

    this.getMotive();
    this.getCererilDepuse();
  }

  selectedStatut: number = -1;

  query: string = '';

  cerereList: CerereModel[] = [];

  selectedYear = 0;

  motiveRespingere: string[] = [];
  motiveTransfer: string[] = [];

  changeStatut(statut: number) {
    this.selectedStatut = statut;
    this.getCererilDepuse();
  }

  getMotive() {
    this.mainService.getMotive().subscribe((data) => {
      if(data.status) {
        this.motiveRespingere = data.motive_respingere;
        this.motiveTransfer = data.motive_transfer;
      }
    }, error => {
      console.log(error);
    })
  }

  getCererilDepuse() {
    if(this.selectedStatut != 4) {
      this.cereriService.cererile_depuse(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK'), String(this.selectedStatut),
        String(this.selectedYear), this.query).subscribe((data) => {
        if (data.status) {
          const cereri = data.cereri;

          for (let i = 0; i < cereri.length; i++) {
            cereri[i].normalized_cerere_number = String(cereri[i].id);

            while (cereri[i].normalized_cerere_number.length < 4) {
              cereri[i].normalized_cerere_number = '0' + cereri[i].normalized_cerere_number;
            }
          }

          this.cerereList = cereri;
        }
      }, error => {
        console.log(error);
      })
    }
    else if(this.selectedStatut == 4) {
      this.cereriService.cererile_transferate(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK'), String(this.selectedStatut),
        String(this.selectedYear), this.query).subscribe((data) => {
        if (data.status) {
          const cereri = data.cereri;

          for (let i = 0; i < cereri.length; i++) {
            cereri[i].normalized_cerere_number = String(cereri[i].id);

            while (cereri[i].normalized_cerere_number.length < 4) {
              cereri[i].normalized_cerere_number = '0' + cereri[i].normalized_cerere_number;
            }
          }

          this.cerereList = cereri;
        }
      }, error => {
        console.log(error);
      })
    }
  }

  descarcaToateDocumentele(cerere: CerereModel) {
    if(cerere.documente_incarcate.length != 0) {
      this.descarcaDocumentul(cerere.documente_incarcate, 0);
    }
  }

  descarcaDocumentul(listaDeDocumente: DocumentModel[], pozitia: number) {
    this.cereriService.download_documentul(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK'),
      String(listaDeDocumente[pozitia].id)).subscribe((data) => {

      saveAs(data, listaDeDocumente[pozitia].document_name);

      if(pozitia != listaDeDocumente.length - 1) {
        this.descarcaDocumentul(listaDeDocumente, pozitia + 1);
      }

    }, error => {
      if(pozitia != listaDeDocumente.length - 1) {
        this.descarcaDocumentul(listaDeDocumente, pozitia + 1);
      }

      console.log(error);
    })
  }

  goToModificaGradinita() {
    this.router.navigateByUrl('/logare-director-completare-info-gradinita');
  }

  closeRespingereCerere(cerere: CerereModel) {
    cerere.cerere_respinge_deschis = false;
    cerere.cerere_motiv = -1;
    cerere.cerere_comentariu = '';
  }

  respingeCererea(cerere: CerereModel) {
    this.directorCommunication.setCerereRespins(cerere.id, cerere.cerere_comentariu, cerere.cerere_motiv);
    this.router.navigateByUrl('/respinge-confirm');
  }

  closeSolicitaActe(cerere: CerereModel) {
    cerere.cerere_solicita_acte_deschis = false;
    cerere.cerere_comentariu = '';
  }

  solicitaActe(cerere: CerereModel) {
    this.cereriService.solicita_acte(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK'), String(cerere.id),
      cerere.cerere_comentariu).subscribe((data) => {
        if(data.status) {
          this.getCererilDepuse();
        }
    }, error => {
       console.log(error);
    });
  }

  closeInregistreazaPentruAplicare(cerere: CerereModel) {
    cerere.cerere_inregistrare_pentru_aplicare_deschis = false;
    cerere.cerere_comentariu = '';
  }

  inregistreazaPentruAplicare(cerere: CerereModel) {
    this.cereriService.inmatriculare_aplicare(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK'), String(cerere.id),
      cerere.cerere_comentariu).subscribe((data) => {
        if(data.status) {
          this.getCererilDepuse();
        }
    }, error => {
        console.log(error);
    })
  }

  inmatriculeaza(cerere: CerereModel) {
    this.directorCommunication.cerere_id = cerere.id;
    this.router.navigateByUrl('/inmatriculeaza-confirm');
  }

  closeTransfer(cerere: CerereModel) {
    cerere.cerere_transfer_deschis = false;
    cerere.cerere_motiv = -1;
    cerere.cerere_comentariu = '';
  }

  transferaCererea(cerere: CerereModel) {
    this.directorCommunication.gradinita_id = cerere.gradinita_id;
    this.directorCommunication.client_id = cerere.client_id;
    this.directorCommunication.setCerereRespins(cerere.id, cerere.cerere_comentariu, cerere.cerere_motiv);
    this.router.navigateByUrl('/transfera-cerere');
  }

}

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {GradiniteService} from '../services/gradinite-service';
import {GradinitaModel} from '../models/gradinita/submodel/gradinita-model';
import {SectorModel} from '../models/gradinita/submodel/sector-model';
import {ParinteCommunication} from '../services/parinte-communication';

@Component({
  selector: 'app-customer-main',
  templateUrl: './customer-main.component.html',
  styleUrls: ['./customer-main.component.css']
})
export class CustomerMainComponent implements OnInit {

  constructor(private router: Router, private gradiniteService: GradiniteService, private parinteCommunication: ParinteCommunication) {
    if(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza') == null) {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit() {

    this.currentTime = new Date().getTime();

    this.getSectoare();
    this.getGradiniteSectoare();
  }

  sectoare: SectorModel[] = [];

  currentTime: number = 0;

  gradinitaList: GradinitaModel[] = [];

  sectorulAles = 0;
  query = '';

  schimbaSectorul(sector: number) {
    this.sectorulAles = sector;
    this.getGradiniteSectoare();
  }

  cerereNoua(gradinita: GradinitaModel) {
    if(gradinita.locuri_disponibile > 0 && gradinita.termen_limita >= this.currentTime) {
      this.parinteCommunication.setGradinitaIdSelected(gradinita.id);
      this.router.navigateByUrl('/depune-cerere');
    }
  }

  getSectoare() {
    this.gradiniteService.sectoare(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza')).subscribe((data) => {
      if(data.status) {
        this.sectoare = data.sectoare;
      }
    }, error => {
      console.log(error);
    })
  }

  getGradiniteSectoare() {
    this.gradiniteService.gradinite_sector(localStorage.getItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza'), String(this.sectorulAles), this.query).subscribe((data) => {
      if(data.status) {
        this.gradinitaList = data.gradinite;
      }
    }, error => {
      console.log(error);
    })
  }

  toataInformatiaGradinita(gradinita: GradinitaModel) {
    this.parinteCommunication.setGradinitaIdSelected(gradinita.id);
    this.router.navigateByUrl('/parinte-main-detalii-grad');
  }

  openCerereDepusa(gradinita: GradinitaModel) {
    this.parinteCommunication.setGradinitaIdSelected(gradinita.id);
    this.parinteCommunication.setCerereIdSelectd(gradinita.cerere_id);
    this.router.navigateByUrl('/modifica-cerere');
  }
}

<div class="main-page-container">
  <section class="welcome-reg-container d-flex flex-column justify-content-center align-items-center w-100">
    <h1 class="main-header">Bine ati venit E-grădinița Căuseni</h1>
    <h3 class="main-header-sub">Aici puteți beneficia de oportunitatea de a înscrie<br>
      copii la grădiniță online doar în câțiva pași simpli de
      urmat.</h3>
    <input [(ngModel)]="codAutentificare" type="text" class="gen-input welcome-input w-100 mb-4" placeholder="Cod autentificare">
    <button (click)="autentificare()" class="button green main-log-button">Autentificare</button>
  </section>
  <section #despre_noi class="about-info-container row w-100 m-0">
    <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 pl-0 overflow-hidden"><img src="../../assets/mainp/inrec%20cop%201.png"></div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
      <h1 class="position-relative width-fit header-default">Înregistrează copilul la grădiniță în doar câteva <span class="position-relative">click-uri !
      <span class="underline-header-container"><img src="../../assets/mainp/Group%206.png" class="underline-header"></span>
      </span></h1>
      <p class="text-default-sub-header">Acum ai posibilitatea de a înregistra copilul la grădiniță potrivita în doar câțiva pași ușor de urmat
        fără a părăsi locuința, E-grădinița îti oferă posibilitatea de a găsi instituția potrivită pentru copilul tau
        și de a depune cererea de înmatriculare online, oferind cât informația despre instituția dată atât și
        posibilitatea de a vizualiza registrul public de cereri depuse la aceasta.</p>
      <button (click)="goToInregistrare()" class="button green main-log-button">Înregistrare</button>
    </div>
  </section>
  <section class="general-apply-periods flex-column w-100 d-none">
    <h1 class="main-header mb-5">Perioadele de inmatriculare</h1>
    <h3 class="main-header-sub">Etapa 1 : 20 iunie - 20-iulie depunerea tuturor cererilor conform adreselor de domiciliu si inmatricularea copiilor.</h3>
    <h3 class="main-header-sub">Etapa 2 : 20 iunie - 20-iulie depunerea cererilor si completarea locurilor ramase.</h3>
  </section>
  <section #instructiune_de_aplicare class="steps-of-application-container row flex-wrap justify-content-around w-100 ml-0 mr-0 d-none">
    <div class="step-container d-flex flex-column mt-2 mb-2 align-items-center">
      <img class="step-img mb-2" src="../../assets/mainp/user%201.png">
      <p class="step-text">Înregistreazăte</p>
    </div>
    <div class="step-container d-flex flex-column mt-2 mb-2 align-items-center">
      <img class="step-img mb-2" src="../../assets/mainp/find%201.png">
      <p class="step-text">Găsește grădinița potrivită</p>
    </div>
    <div class="step-container d-flex flex-column mt-2 mb-2 align-items-center">
      <img class="step-img mb-2" src="../../assets/mainp/clipboard%201.png">
      <p class="step-text">Completează formularul</p>
    </div>
    <div class="step-container d-flex flex-column mt-2 mb-2 align-items-center">
      <img class="step-img mb-2" src="../../assets/mainp/approved%201.png">
      <p class="step-text">Primește aprobarea</p>
    </div>
    <div class="step-container d-flex flex-column mt-2 mb-2 align-items-center">
      <img class="step-img mb-2" src="../../assets/mainp/meeting%201.png">
      <p class="step-text">Prezintăte la instituție</p>
    </div>
  </section>
  <section class="gen-stats-container row w-100 ml-0 mr-0 ">
    <div class="stats-cont col-12 col-md-4 d-flex flex-column justify-content-around align-items-center">
      <p class="stats-head mt-2 mb-1">Cereri depuse</p>
      <p class="stats-text mt-1 mb-2">{{ cereriDepuse }}</p>
    </div>
    <div class="stats-cont col-12 col-md-4 d-flex flex-column justify-content-around align-items-center">
      <p class="stats-head mt-2 mb-1">Cereri procesate</p>
      <p class="stats-text mt-1 mb-2">{{ cereriProcesate }}</p>
    </div>
    <div class="stats-cont col-12 col-md-4 d-flex flex-column justify-content-around align-items-center">
      <p class="stats-head mt-2 mb-1">Cereri acceptate</p>
      <p class="stats-text mt-1 mb-2">{{ cereriAcceptate }}</p>
    </div>
  </section>
  <section #inregistrare_view class="footer-reg-container d-flex flex-column justify-content-center align-items-center w-100">
    <h1 class="position-relative width-fit header-default">Înregistrarea contului <span class="position-relative">E-gradinița
      <span class="underline-header-container"><img src="../../assets/mainp/Group%206.png" class="underline-header"></span>
      </span></h1>
    <input [(ngModel)]="email" type="email" class="gen-input-white-bkg welcome-input w-100 mb-4 mt-5" placeholder="example@email.com" >
    <button (click)="inregistrare()" class="button green main-log-button mt-3">Înregistrare</button>
    <p class="text-reg-information mt-5">* Indicați adresa de email pentru a fi folosită sau care a fost folosită pentru înregistrare ca să accesați contul E-gradinița</p>
  </section>
</div>

<div class="customer-main-container d-flex flex-column w-100">
  <div class="view-tabs-back-container d-flex align-items-end justify-content-between flex-wrap">
    <div class="view-tabs-back-min-container" style="min-height: 1.5rem !important;">
      <ul class="back-cont d-flex align-items-end">
        <li (click)="inapoiLaGradinita()"><img class="back-arrow-img" src="../../assets/mainp/back%201.png"> Inapoi la gradinita</li>

      </ul>
    </div>
    <div class="grad-gen-info-container d-flex align-items-end mt-2">
      <img src="../../assets/mainp/school%201.png" class="gen-left-img">
      <div class="gen-grad-info-container d-flex ml-3 flex-column">
        <span class="grad-name-container">{{ gradinitaNume }}</span>
      </div>
    </div>
  </div>



  <div class="show-grad-container-main d-flex flex-column w-100">

    <div *ngFor="let cereri of cereriList" class="grad-container w-100 mt-2 mb-3">
      <div class="upper-grad-min-container d-flex justify-content-between flex-wrap">
        <div class="grad-gen-info-container d-flex mb-1">
          <img src="../../assets/mainp/resume%201.png" class="gen-left-img">
          <div class="gen-grad-info-container d-flex ml-3 flex-column">
            <span class="grad-name-container mb-3">{{ cereri.copil_prenume }}</span>
            <span class="grad-sub-info-container">Data aplicarii : <span class="available"> {{ cereri.data_crearii | date: 'dd/MM/yyyy' }} </span></span>
          </div>
        </div>
        <div class="grad-options-container d-flex flex-column justify-content-between align-items-center">
          <span *ngIf="cereri.statut == 3" id="accepted-status" class="cerere-status align-items-center"><p class="status-text">Acceptata</p><img src="../../assets/mainp/check-mark.png" class="status-img"></span>
          <span *ngIf="cereri.statut == 0" id="processing-status" class="cerere-status align-items-center"><p class="status-text">Se proceseaza</p><img src="../../assets/mainp/processing.png" class="status-img"></span>
          <span *ngIf="cereri.statut == 1" id="declined-status" class="cerere-status align-items-center"><p class="status-text">Refuzata</p><img src="../../assets/mainp/cancel.png" class="status-img"></span>
          <span *ngIf="cereri.statut == 2" id="apply-documents-status" class="cerere-status align-items-center"><p class="status-text">Spre aplicare</p><img src="../../assets/mainp/documents.png" class="status-img"></span>
        </div>
      </div>
    </div>





  </div>

</div>

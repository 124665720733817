<nav class="navbar navbar-expand-lg navbar-light bg-light">



  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">
      <li [ngClass]="router.url == '/' ? 'active' : ''" (click)="goToAcasa()" class="nav-item">
        <span class="nav-link">Acasă</span>
      </li>

      <li *ngIf="logged" [ngClass]="(router.url == '/parinte-main' && loggedType == 0) || (router.url == '/lista-aplicari' && loggedType == 999) ? 'active' : ''" (click)="goToProfil()" class="nav-item">
        <span class="nav-link">Profil</span>
      </li>

      <li class="nav-item dropdown">
        <span class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Informații utile
        </span>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <span (click)="goToDespreNoi()" class="dropdown-item">Despre noi</span>
          <span (click)="goToIntrebariFrecvente()" class="dropdown-item">Întrebări frecvente</span>
          <span (click)="goToInstructiuniDeAplicare()" class="dropdown-item">Instrucțiuni de aplicare</span>
        </div>
      </li>

<!--      <li class="nav-item">-->
<!--        <span class="nav-link">Despre noi</span>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <span class="nav-link">Întrebări frecvente</span>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <span class="nav-link">Instrucțiuni de aplicare</span>-->
<!--      </li>-->
      <li (click)="goToRegulament()" class="nav-item">
        <span class="nav-link">Regulament</span>
      </li>
      <li *ngIf="!logged" (click)="goToAutentificare()" [ngClass]="router.url == '/logare-parinte' ? 'active' : ''" class="nav-item ">
        <span class="nav-link">Autentificare</span>
      </li>
      <li *ngIf="!logged" (click)="goToInregistrare()" [ngClass]="router.url == '/inregistrare-parinte' ? 'active' : ''" class="nav-item ">
        <span class="nav-link">Înregistrare</span>
      </li>
<!--   logged-hide si logged-show sunt clasele pentru a ascunde si arata butoanele in dependenta de starea de logare.   -->
      <li *ngIf="logged" (click)="logOut()" class="nav-item ">
        <span class="nav-link">Ieșire</span>
      </li>
      <li class="nav-item dropdown">
        <span style="color:var(--green-color) !important;" class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Ro
        </span>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <span class="dropdown-item active">Ro</span>
          <span class="dropdown-item">Py</span>
        </div>
      </li>
    </ul>
  </div>

<!--  <span class="navbar-brand d-none d-sm-block">Navbar</span>-->
  <span class="navbar-brand d-flex align-items-center"><img src="../../assets/mainp/stema.BMP"><span><p class="ml-3 info-prim">E-grădinița Căușeni proiect <br> implementat de Primăria Căușeni</p></span></span>

</nav>

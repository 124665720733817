<section class="customer-reg-container d-flex flex-column justify-content-center align-items-center w-100">
  <h1 class="position-relative width-fit header-default">Crearea contului directorului <span class="position-relative">E-gradinita
      <span class="underline-header-container"><img src="../../assets/mainp/Group%206.png" class="underline-header"></span>
      </span></h1>
  <input [(ngModel)]="email" type="email" class="gen-input-white-bkg welcome-input w-100 mb-3 mt-5" placeholder="Email cont director">
  <input [(ngModel)]="password" type="password" class="gen-input-white-bkg welcome-input w-100 mb-3 mt-3" placeholder="Parola cont director">
  <input [(ngModel)]="nume_director" type="text" class="gen-input-white-bkg welcome-input w-100 mb-4 mt-3" placeholder="Nume director">
  <div class="d-flex justify-content-between w-100" style="max-width: 350px;">
    <button (click)="anulare()" class="button red main-log-button mt-3" style="width: 150px;">Anulare</button>
    <button (click)="pasulUrmator()" class="button green main-log-button mt-3" style="width: 150px;">Pasul urmator</button>
  </div>
</section>

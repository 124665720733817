import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {GradiniteService} from '../services/gradinite-service';
import {DirectorCommunication} from '../services/director-communication';
import {CereriService} from '../services/cereri-service';

@Component({
  selector: 'app-dir-inmatriculare-confirm',
  templateUrl: './dir-inmatriculare-confirm.component.html',
  styleUrls: ['./dir-inmatriculare-confirm.component.css']
})
export class DirInmatriculareConfirmComponent implements OnInit {

  constructor(private router: Router, private gradiniteService: GradiniteService, private directorCommunication: DirectorCommunication,
              private cereriService: CereriService) {
    if(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK') == null) {
      this.router.navigateByUrl('/');
    }
    else {
      this.gradiniteService.informatie_completata(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK')).subscribe((data) => {
        if (data.status) {
          if (!data.completed) {
            this.router.navigateByUrl('/logare-director-completare-info-gradinita');
          }
        }
      }, error => {
        console.log(error);
      })
    }

    if(this.directorCommunication.cerere_id == 0) {
      this.router.navigateByUrl('/lista-aplicari');
    }
  }

  ngOnInit(): void {
  }

  inmatriculeazaCererea() {
    this.cereriService.inmatriculare(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK'),
      String(this.directorCommunication.cerere_id)).subscribe((data) => {
        if(data.status) {
          this.router.navigateByUrl('/lista-aplicari');
        }
    }, error => {
        console.log(error);
    })
  }

}

<div class="customer-main-container d-flex flex-column w-100">
  <div class="view-tabs-back-container d-flex align-items-end justify-content-between flex-wrap">
    <div class="view-tabs-back-min-container" style="min-height: 3rem !important;">
      <ul class="tabs-cont d-flex align-items-end">
        <li [ngClass]="selectedStatut == -1 ? 'active' : ''" (click)="changeStatut(-1)">Toate</li>
        <li [ngClass]="selectedStatut == 3 ? 'active' : ''" (click)="changeStatut(3)">Acceptate</li>
        <li [ngClass]="selectedStatut == 2 ? 'active' : ''" (click)="changeStatut(2)">Inregistrat pentru aplicare</li>
        <li [ngClass]="selectedStatut == 0 ? 'active' : ''" (click)="changeStatut(0)">In procesare</li>
        <li [ngClass]="selectedStatut == 1 ? 'active' : ''" (click)="changeStatut(1)">Refuzate</li>
      </ul>
    </div>
    <button (click)="cerereNoua()" class="button green">Cerere noua</button>
  </div>



  <div class="show-grad-container-main d-flex flex-column w-100">

    <div *ngFor="let cerere of cereriList" class="grad-container w-100 mt-2 mb-3">
      <div class="upper-grad-min-container d-flex justify-content-between flex-wrap">
        <div class="grad-gen-info-container d-flex mb-1">
          <div class="gen-grad-info-container d-flex ml-3 flex-column">
            <span class="grad-sub-info-container">
              <span class="available">#{{ cerere.normalized_cerere_number }}</span>
              <span class="available ml-4">{{ cerere.data_crearii | date: 'HH:mm' }}</span>
              <span class="available ml-4">{{ cerere.data_crearii | date: 'dd/MM/yyyy' }}</span>
              <button *ngIf="!cerere.cerere_details_opened" class="open-close-info-button open-button ml-4" (click)="cerere.cerere_details_opened = true;"> Vezi detalii <img src="../../assets/mainp/down-arrow%201.png" class="open-close-arrow-img open "></button>
              <button *ngIf="cerere.cerere_details_opened" class="open-close-info-button close-button ml-4" (click)="cerere.cerere_details_opened = false;"> Ascunde <img src="../../assets/mainp/down-arrow%201.png" class="open-close-arrow-img close "></button>
              <span *ngIf="(cerere.statut != 1 && cerere.statut != 3 && cerere.statut != 2) && cerere.solicitare_acte" class="acte-aditionale ml-4">Au fost solicitate acte aditionale</span>
              <span *ngIf="(cerere.statut != 1 && cerere.statut != 3) && cerere.transferat" class="cerere-transferata ml-4">Cererea a fost transferata</span>
            </span>
            <span class="grad-name-container mt-3">{{ cerere.nume_gradinita }}</span>
          </div>
        </div>
        <div class="grad-options-container d-flex flex-column justify-content-between align-items-center">
          <span *ngIf="cerere.statut == 3" id="accepted-status" class="cerere-status align-items-center"><p class="status-text">Acceptata</p><img src="../../assets/mainp/check-mark.png" class="status-img"></span>
          <span *ngIf="cerere.statut == 0" id="processing-status" class="cerere-status align-items-center"><p class="status-text">Se proceseaza</p><img src="../../assets/mainp/processing.png" class="status-img"></span>
          <span *ngIf="cerere.statut == 1" id="declined-status" class="cerere-status align-items-center"><p class="status-text">Refuzata</p><img src="../../assets/mainp/cancel.png" class="status-img"></span>
          <span *ngIf="cerere.statut == 2" id="apply-documents-status" class="cerere-status align-items-center"><p class="status-text">Spre aplicare</p><img src="../../assets/mainp/documents.png" class="status-img"></span>
        </div>
      </div>
      <div *ngIf="cerere.cerere_details_opened" class="w-100 vezi-info-cereri-depuse flex-column">
        <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column">
          <span class="address-header mb-4">Limba de instruire</span>
          <div class="inputs-label-container d-flex flex-column w-100">
            <p class="show-info-cereri-depuse mt-4" style="margin-left: 1rem;">
              {{ cerere.limba_instruire }}
            </p>
          </div>
        </div>
        <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column info-parinte-copil-container">
          <span class="address-header">Informatie Parinte</span>

          <div class="d-flex flex-wrap  w-100 mt-4">
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">IDNP</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.parinte_idnp }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Nume/Prenume</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.parinte_nume }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Nr. Telefon</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.parinte_telefon }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Email</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.parinte_email }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Localitate</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.parinte_localitate }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Adresa</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.parinte_adresa }}
              </p>
            </div>
          </div>

        </div>
        <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column info-parinte-copil-container">
          <span class="address-header">Informatie Copil</span>

          <div class="d-flex flex-wrap  w-100 mt-4">
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">IDNP</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.copil_idnp }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Nume/Prenume</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.copil_prenume }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Anul nasterii</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.copil_anul_nasterii }}
              </p>
            </div>
            <div class="inputs-label-container d-flex flex-column w-100">
              <label class="inputs-label">Sexul</label>
              <p class="show-info-cereri-depuse">
                {{ cerere.copil_sexul }}
              </p>
            </div>
          </div>

        </div>
        <div class="d-flex flex-wrap mt-4 pt-2 align-items-start justify-content-end flex-column caz-prior">
          <span class="address-header mb-4">Caz cu prioritate</span>

          <ul class="caz-prior-list">
            <li *ngFor="let caz of cerere.caz_prioritate"><input type="checkbox" checked>{{ caz }}</li>
          </ul>
        </div>
        <div>
          <div *ngIf="(cerere.statut == 1 || cerere.statut == 2) || (cerere.statut == 0 && (cerere.transferat || cerere.solicitare_acte))" class="inputs-label-container w-100 d-flex flex-column ">
            <label class="inputs-label">Comentariu</label>
            <p>{{ cerere.comentariu }}</p>
          </div>
          <div *ngIf="cerere.statut == 1 || (cerere.transferat && cerere.statut == 0)" class="inputs-label-container d-flex flex-column w-100 mt-3">
            <label class="inputs-label">Motiv</label>
            <p>{{ cerere.motiv }}</p>
          </div>
        </div>
        <div class="d-flex  flex-wrap justify-content-center buttons-width">
          <button *ngIf="cerere.statut != 3 && cerere.statut != 1" class="button green mt-5" (click)="modificaDatele(cerere)">Modifica datele</button>
          <button class="button blue ml-4 mt-5" style="position: relative;padding-left: 4rem;display: flex;align-items: center;" (click)="documenteleAtasate(cerere)"><img style="position: absolute; left: 10px;" src="../../assets/mainp/upload%20(1)%201.png">Documente atasate</button>
          <button *ngIf="cerere.statut != 3 && cerere.statut != 1" class="button red ml-4 mt-5" (click)="retrageCererea(cerere)">Retrage cererea</button>
        </div>
      </div>

    </div>





  </div>

</div>

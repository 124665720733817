import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthorizationService} from '../services/authorization-service';
import {MainCommunication} from '../services/main-communication';

@Component({
  selector: 'app-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrls: ['./customer-login.component.css']
})
export class CustomerLoginComponent implements OnInit {

  constructor(private router: Router, private autorizareService: AuthorizationService, private mainCommunication: MainCommunication) { }

  ngOnInit() {
  }

  codAutentificare: string;

  goToInregistrare() {
    this.router.navigateByUrl('/inregistrare-parinte');
  }

  autentificare() {
    if(this.codAutentificare.length == 0) {
      alert('Cimpul cu Codul de autentificare este obligatoriu');
    }
    else{
      this.autorizareService.logare_client(this.codAutentificare).subscribe((data) => {
        if(data.status) {

          localStorage.setItem('HBDMwHAx3ME6hKkfZgZrEahBs5Vtmrza', data.token);
          localStorage.setItem('logged_in', 'customer');

          this.mainCommunication.loggedChanged();

          this.router.navigateByUrl('/parinte-main');
        }
        else {
          alert(data.message);
        }
      }, error => {
        alert('Codul de autentificare este gresit');
      })
    }
  }

}

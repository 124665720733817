import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MainComponent } from './main/main.component';
import { UpperNavComponent } from './upper-nav/upper-nav.component';
import { FooterComponent } from './footer/footer.component';
import { CustomerRegisterComponent } from './customer-register/customer-register.component';
import { CustomerLoginComponent } from './customer-login/customer-login.component';
import { CustomerLoginEmailLinkComponent } from './customer-login-email-link/customer-login-email-link.component';
import { LogDirFirstComponent } from './log-dir-first/log-dir-first.component';
import { LogDirActivateComponent } from './log-dir-activate/log-dir-activate.component';
import { LogDirSecondComponent } from './log-dir-second/log-dir-second.component';
import { LogDirSuccessComponent } from './log-dir-success/log-dir-success.component';
import { LogDirCompleteGradInfoComponent } from './log-dir-complete-grad-info/log-dir-complete-grad-info.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import { CustomerMainComponent } from './customer-main/customer-main.component';
import { UpperTabsLoggedComponent } from './upper-tabs-logged/upper-tabs-logged.component';
import { CustomerViewGradDetailsComponent } from './customer-view-grad-details/customer-view-grad-details.component';
import { CustomerViewGradDetailsAppliedComponent } from './customer-view-grad-details-applied/customer-view-grad-details-applied.component';
import { RegPublicComponent } from './reg-public/reg-public.component';
import { DepuneCerereaComponent } from './depune-cererea/depune-cererea.component';
import { ModificaCerereaComponent } from './modifica-cererea/modifica-cererea.component';
import {TooltipModule} from "ngx-bootstrap/tooltip";
import { CerereDepusaSuccessComponent } from './cerere-depusa-success/cerere-depusa-success.component';
import { CerereDepusaErrorComponent } from './cerere-depusa-error/cerere-depusa-error.component';
import { RetrageCerereaConfirmareComponent } from './retrage-cererea-confirmare/retrage-cererea-confirmare.component';
import { CereriDepuseComponent } from './cereri-depuse/cereri-depuse.component';
import { DocumenteAtasateComponent } from './documente-atasate/documente-atasate.component';
import { DirApplicationsListComponent } from './dir-applications-list/dir-applications-list.component';
import { DirApplicationTransferComponent } from './dir-application-transfer/dir-application-transfer.component';
import { DirTransferConfirmComponent } from './dir-transfer-confirm/dir-transfer-confirm.component';
import { DirCancelConfirmComponent } from './dir-cancel-confirm/dir-cancel-confirm.component';
import { DirInmatriculareConfirmComponent } from './dir-inmatriculare-confirm/dir-inmatriculare-confirm.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    UpperNavComponent,
    FooterComponent,
    CustomerRegisterComponent,
    CustomerLoginComponent,
    CustomerLoginEmailLinkComponent,
    LogDirFirstComponent,
    LogDirActivateComponent,
    LogDirSecondComponent,
    LogDirSuccessComponent,
    LogDirCompleteGradInfoComponent,
    CustomerMainComponent,
    UpperTabsLoggedComponent,
    CustomerViewGradDetailsComponent,
    CustomerViewGradDetailsAppliedComponent,
    RegPublicComponent,
    DepuneCerereaComponent,
    ModificaCerereaComponent,
    CerereDepusaSuccessComponent,
    CerereDepusaErrorComponent,
    RetrageCerereaConfirmareComponent,
    CereriDepuseComponent,
    DocumenteAtasateComponent,
    DirApplicationsListComponent,
    DirApplicationTransferComponent,
    DirTransferConfirmComponent,
    DirCancelConfirmComponent,
    DirInmatriculareConfirmComponent
  ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        TooltipModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

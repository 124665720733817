import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {GradiniteService} from '../services/gradinite-service';
import {DirectorCommunication} from '../services/director-communication';
import {CereriService} from '../services/cereri-service';
import {MainService} from '../services/main-service';

@Component({
  selector: 'app-dir-transfer-confirm',
  templateUrl: './dir-transfer-confirm.component.html',
  styleUrls: ['./dir-transfer-confirm.component.css']
})
export class DirTransferConfirmComponent implements OnInit {

  constructor(private router: Router, private gradiniteService: GradiniteService, private directorCommunication: DirectorCommunication,
              private cereriService: CereriService, private mainService: MainService) {
    if(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK') == null) {
      this.router.navigateByUrl('/');
    }
    else {
      this.gradiniteService.informatie_completata(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK')).subscribe((data) => {
        if (data.status) {
          if (!data.completed) {
            this.router.navigateByUrl('/logare-director-completare-info-gradinita');
          }
        }
      }, error => {
        console.log(error);
      })
    }

    if(this.directorCommunication.cerere_id == 0 || this.directorCommunication.to_gradinita_id == 0) {
      this.router.navigateByUrl('/lista-aplicari');
    }
  }

  ngOnInit(): void {
    this.getMotive();
  }

  motiveTransfer: string[] = [];

  getMotive() {
    this.mainService.getMotive().subscribe((data) => {
      if(data.status) {
        this.motiveTransfer = data.motive_transfer;
      }
    }, error => {
      console.log(error);
    })
  }

  transferaCererea() {
    let motivString = '';

    if(this.directorCommunication.cerere_respingere_motiv != -1) {
      motivString = this.motiveTransfer[this.directorCommunication.cerere_respingere_motiv];
    }

    this.cereriService.transfer_cererea(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK'),
      String(this.directorCommunication.cerere_id), this.directorCommunication.cerere_respingere_comentariu, motivString,
      String(this.directorCommunication.to_gradinita_id)).subscribe((data) => {
        if(data.status) {
          this.router.navigateByUrl('/lista-aplicari');
        }
    }, error => {
        console.log(error);
    })
  }
}

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {GradiniteService} from '../services/gradinite-service';
import {DirectorCommunication} from '../services/director-communication';
import {SectorModel} from '../models/gradinita/submodel/sector-model';
import {GradinitaModel} from '../models/gradinita/submodel/gradinita-model';

@Component({
  selector: 'app-dir-application-transfer',
  templateUrl: './dir-application-transfer.component.html',
  styleUrls: ['./dir-application-transfer.component.css']
})
export class DirApplicationTransferComponent implements OnInit {

  constructor(private router: Router, private gradiniteService: GradiniteService, private directorCommunication: DirectorCommunication) {
    if(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK') == null) {
      this.router.navigateByUrl('/');
    }
    else {
      this.gradiniteService.informatie_completata(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK')).subscribe((data) => {
        if (data.status) {
          if (!data.completed) {
            this.router.navigateByUrl('/logare-director-completare-info-gradinita');
          }
        }
      }, error => {
        console.log(error);
      })
    }

    if(this.directorCommunication.gradinita_id == 0 || this.directorCommunication.cerere_id == 0) {
      this.router.navigateByUrl('/lista-aplicari');
    }
  }

  ngOnInit(): void {
    this.currentTime = new Date().getTime();

    this.getSectoare();
    this.getGradiniteSectoare();
  }

  sectoare: SectorModel[] = [];

  currentTime: number = 0;

  gradinitaList: GradinitaModel[] = [];

  sectorulAles = 0;
  query = '';

  anuleazaTransferul() {
    this.directorCommunication.clearData();
    this.router.navigateByUrl('/lista-aplicari')
  }

  schimbaSectorul(sector: number) {
    this.sectorulAles = sector;
    this.getGradiniteSectoare();
  }

  getSectoare() {
    this.gradiniteService.sectoare(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK')).subscribe((data) => {
      if(data.status) {
        this.sectoare = data.sectoare;
      }
    }, error => {
      console.log(error);
    })
  }

  getGradiniteSectoare() {
    this.gradiniteService.gradinite_sector_director(localStorage.getItem('33MGmB8FN5C6AU8rt6bA9pwDs3bK9PMK'),
      String(this.sectorulAles), this.query, String(this.directorCommunication.client_id)).subscribe((data) => {
      if(data.status) {
        const gradinite = data.gradinite;

        let positionToRemove = -1;

        for(let i = 0; i < gradinite.length; i++) {
          if(gradinite[i].id == this.directorCommunication.gradinita_id) {
            positionToRemove = i;
          }
        }

        if(positionToRemove != -1) {
          gradinite.splice(positionToRemove, 1);
        }

        this.gradinitaList = gradinite;
      }
    }, error => {
      console.log(error);
    })
  }

  transferaCererea(gradinita: GradinitaModel) {
    if(gradinita.termen_limita >= this.currentTime && gradinita.locuri_disponibile > 0) {
      this.directorCommunication.to_gradinita_id = gradinita.id;
      this.router.navigateByUrl('/transfera-confirm');
    }
  }

}

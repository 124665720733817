import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-view-grad-details-applied',
  templateUrl: './customer-view-grad-details-applied.component.html',
  styleUrls: ['./customer-view-grad-details-applied.component.css']
})
export class CustomerViewGradDetailsAppliedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

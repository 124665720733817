import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MainComponent} from './main/main.component';
import {CustomerRegisterComponent} from "./customer-register/customer-register.component";
import {CustomerLoginComponent} from "./customer-login/customer-login.component";
import {CustomerLoginEmailLinkComponent} from "./customer-login-email-link/customer-login-email-link.component";
import {LogDirFirstComponent} from "./log-dir-first/log-dir-first.component";
import {LogDirActivateComponent} from "./log-dir-activate/log-dir-activate.component";
import {LogDirSecondComponent} from "./log-dir-second/log-dir-second.component";
import {LogDirCompleteGradInfoComponent} from "./log-dir-complete-grad-info/log-dir-complete-grad-info.component";
import {LogDirSuccessComponent} from "./log-dir-success/log-dir-success.component";
import {CustomerMainComponent} from "./customer-main/customer-main.component";
import {CustomerViewGradDetailsComponent} from "./customer-view-grad-details/customer-view-grad-details.component";
import {CustomerViewGradDetailsAppliedComponent} from "./customer-view-grad-details-applied/customer-view-grad-details-applied.component";
import {RegPublicComponent} from "./reg-public/reg-public.component";
import {DepuneCerereaComponent} from "./depune-cererea/depune-cererea.component";
import {ModificaCerereaComponent} from "./modifica-cererea/modifica-cererea.component";
import {CerereDepusaSuccessComponent} from "./cerere-depusa-success/cerere-depusa-success.component";
import {CerereDepusaErrorComponent} from "./cerere-depusa-error/cerere-depusa-error.component";
import {RetrageCerereaConfirmareComponent} from "./retrage-cererea-confirmare/retrage-cererea-confirmare.component";
import {CereriDepuseComponent} from "./cereri-depuse/cereri-depuse.component";
import {DocumenteAtasateComponent} from "./documente-atasate/documente-atasate.component";
import {DirApplicationsListComponent} from "./dir-applications-list/dir-applications-list.component";
import {DirApplicationTransferComponent} from "./dir-application-transfer/dir-application-transfer.component";
import {DirCancelConfirmComponent} from "./dir-cancel-confirm/dir-cancel-confirm.component";
import {DirTransferConfirmComponent} from "./dir-transfer-confirm/dir-transfer-confirm.component";
import {DirInmatriculareConfirmComponent} from "./dir-inmatriculare-confirm/dir-inmatriculare-confirm.component";

const routes: Routes = [
  { path: '',
    component: MainComponent,
    data: {upper_nav_logged: false, selected_tab: -1}},
  { path: 'inregistrare-parinte',
    component: CustomerRegisterComponent,
    data: {upper_nav_logged: false, selected_tab: -1} },
  { path: 'logare-parinte',
    component: CustomerLoginComponent,
    data: {upper_nav_logged: false, selected_tab: -1} },
  { path: 'logare-parinte-email',
    component: CustomerLoginEmailLinkComponent,
    data: {upper_nav_logged: false, selected_tab: -1} },
  { path: 'logare-director',
    component: LogDirFirstComponent,
    data: {upper_nav_logged: false, selected_tab: -1} },
  { path: 'activare-acc-director',
    component: LogDirActivateComponent,
    data: {upper_nav_logged: false, selected_tab: -1} },
  { path: 'logare-director-creare',
    component: LogDirSecondComponent,
    data: {upper_nav_logged: false, selected_tab: -1} },
  { path: 'logare-director-completare-info-gradinita',
    component: LogDirCompleteGradInfoComponent,
    data: {upper_nav_logged: false, selected_tab: -1} },
  { path: 'logare-director-success',
    component: LogDirSuccessComponent,
    data: {upper_nav_logged: false, selected_tab: -1} },
  { path: 'parinte-main',
    component: CustomerMainComponent,
    data: {upper_nav_logged: true, selected_tab: 0} },
  { path: 'parinte-main-detalii-grad',
    component: CustomerViewGradDetailsComponent,
    data: {upper_nav_logged: true, selected_tab: 0} },
  { path: 'parinte-main-detalii-grad-depus',
    component: CustomerViewGradDetailsAppliedComponent,
    data: {upper_nav_logged: true, selected_tab: 0} },
  { path: 'registru-public',
    component: RegPublicComponent,
    data: {upper_nav_logged: true, selected_tab: 0} },
  { path: 'depune-cerere',
    component: DepuneCerereaComponent,
    data: {upper_nav_logged: true, selected_tab: -1} },
  { path: 'modifica-cerere',
    component: ModificaCerereaComponent,
    data: {upper_nav_logged: true, selected_tab: -1} },
  { path: 'cerere-succes',
    component: CerereDepusaSuccessComponent,
    data: {upper_nav_logged: true, selected_tab: -1} },
  { path: 'cerere-eroare',
    component: CerereDepusaErrorComponent,
    data: {upper_nav_logged: true, selected_tab: -1} },
  { path: 'retrage-cererea',
    component: RetrageCerereaConfirmareComponent,
    data: {upper_nav_logged: true, selected_tab: -1} },
  { path: 'cereri-depuse',
    component: CereriDepuseComponent,
    data: {upper_nav_logged: true, selected_tab: -1} },
  { path: 'documente-atasate',
    component: DocumenteAtasateComponent,
    data: {upper_nav_logged: true, selected_tab: 1} },
  { path: 'lista-aplicari',
    component: DirApplicationsListComponent,
    data: {upper_nav_logged: true, selected_tab: -1}},
  { path: 'transfera-cerere',
    component: DirApplicationTransferComponent,
    data: {upper_nav_logged: true, selected_tab: -1}},
  { path: 'respinge-confirm',
    component: DirCancelConfirmComponent,
    data: {upper_nav_logged: false, selected_tab: -1}},
  { path: 'transfera-confirm',
    component: DirTransferConfirmComponent,
    data: {upper_nav_logged: false, selected_tab: -1}},
  { path: 'inmatriculeaza-confirm',
    component: DirInmatriculareConfirmComponent,
    data: {upper_nav_logged: false, selected_tab: -1}}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
